$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.faq-page {
  .main-content {
    .call-on-number-mobile {
      font-family: $font-family_1;
      font-style:normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #1E1E26;
      margin-top: 18px;
    }
    .help-line-moboile {
      gap: 10px;
      img {
        height: 17px;
        width: 17px;
      }
      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #78787d;
        span {
          color: #000000;
          font-weight: 700;
        }
      }
    }
    .call-cta {
      margin-top: 40px;
      .call-req-cta-mobile {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        background: #22bbb0;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 40px;
        max-width: 343px;
        width: 100%;
        padding: 16px 0;
      }
    }
    .call-requesd {
      .requested-details {
        gap: 9px;
        img {
          width: 17px;
          height: 18px;
        }
        .p1{
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #78787d;
        }
        .p2{
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #1e1e26;
        }
      }
    }
    .call-req-disabled-cta-mobile {
      margin-top: 50px;
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      background: #a7e4df;
      border-radius: 40px;
      max-width: 343px;
      width: 100%;
      padding: 16px 0;
    }

    .home {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #a5a5a8;
    }
    .support {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #00a095;
    }
    .title {
      padding-left: 16px;
      padding-right: 16px;
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #1e1e26;

      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }
    .popular-queries {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #4b4b51;
      margin-top: 24px;
    }

    // All Faqs
    .all-faqs {
      padding: 0 16px;
      h2 {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4b4b51;
      }
    }

    .contact-us {
      background: #f0faf9;
      padding: 25px 16px;
      .contact-us-title-part {
        display: flex;
        align-items: center;
        gap: 16px;
        padding-bottom: 16px;
        .contact-us-title {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #1e1e26;
          margin-top: 8px;
        }
      }
      .contact-details {
        // align-items: center;
        justify-content: center;
        gap: 12px;
        .contact-details-box {
          max-width: 150px;
          width: 100%;
          .contact-details-box-data {
            border-radius: 14px;
            text-align: center;
          }
          .contact-details-img {
            margin-top: 18px;
            margin-bottom: 18px;
          }
          .contact-details-description {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #000000;
            padding-top: 24px;
            padding-bottom: 30px;
          }
          .contact-details-name {
            padding-bottom: 18px;
            p {
              font-family: $font-family_2;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              color: #ffffff;
              margin-bottom: 0px;
            }
          }
        }
      }
    }

    .modal-dialog .modal-content {
      box-shadow: none;

      //  margin-top:%;
    }
    // forms
    @media (min-width: 1024px) {
      .modal {
        border-radius: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 45vw;
        background: white;
        height: fit-content;
        top: 50%;
        z-index: 100000;
      }
      .modal-dialog {
        margin: 0;
      }
      .modal-content {
        border-radius: 15px !important;
        overflow: hidden;
      }
    }

    .modal-body {
      padding: 0;
      // .row{
      //   margin-left: 0px;
      //   margin-right: 0px;
      // }
      .req-call {
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #1e1e26;
      }
      .help-line {
        gap: 10px;
        img {
          height: 17px;
          width: 17px;
        }
        p {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #78787d;
          span {
            color: #000000;
            font-weight: 700;
          }
        }
      }
      .requested-details {
        gap: 10px;
        img {
          width: 17px;
          height: 17px;
        }
        h1 {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #78787d;
        }
        p {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #1e1e26;
        }
      }
      .call-req-cta {
        background: #22bbb0;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 40px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        padding: 16px;
        width: 244px;
      }
      .call-requesd {
        .call-on-number {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #1e1e26;
        }
      }
      .call-req-disabled-cta {
        background: #a7e4df;
        border-radius: 40px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        padding: 16px;
        width: 244px;
        margin-top: 120px;
      }
    }
  }
  .call-waiting{
    display: flex;
    justify-content: space-between;
    padding:10px ;
    margin:auto;
    margin-bottom: 25px;
    min-width: 343px;
    max-width: 748px;
    height: 140px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--black-20, #D2D2D4);
    background: var(--teal-5, hsl(172, 57%, 97%));
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  
    @media (max-width: 768px) {
      margin: 10px;
    }
    .leftDiv{
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 5px;
    .DNLW-Heading{
      color: var(--black-100, #1E1E26);
      font-family: "Ubuntu";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */

      @media (max-width:1023px){
        color: var(--black-100, #1E1E26);
        font-family: $font-family_1;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 150% */
        }
    }
    .DNLW-Tollfree{
      color: var(--black-60, #78787D);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  
  }
  
  .rightDiv{
    display: flex;
  }
  }

  @media (min-width: 1024px) {
    .main-content {
      padding-left: 0px;
      padding-right: 0px;
        max-width: 900px;
        width: 100%;
        margin: auto;
        margin-top: 150px ;
      .popular-queries {
        font-family: $font-family_2;
        font-size: 20px;
      }

      // All Faqs
      .all-faqs {
        h2 {
          font-family: $font-family_2;
          font-weight: 500;
          font-size: 20px;
          color: #4b4b51;
        }
      }
      .contact-us {
        background: white;
        .contact-us-title-part {
          .contact-us-title {
            font-family: $font-family_2;
            font-size: 20px;
          }
        }
        .contact-details {
          gap: 36px;
          .contact-details-box {
            border-radius: 14px;
            max-width: 216px;
            width: 100%;
            .contact-details-img {
              margin-top: 36px;
              width: 72px;
              height: 72px;
            }
            .contact-details-name {
              gap: 17px;
              padding-bottom: 36px;
              p {
                font-size: 20px;
              }
              img {
                height: 12px;
                width: 6px;
              }
            }
          }
        }
      }
    }
  
}
}
