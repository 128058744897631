.sign-up-container {
  padding: 32px 16px;
  .sign-up-inner-container {
    background-image: url("../../../../../src/assets/images/thank-you-mb-card.png");
    padding: 32px 22px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: contain;
    background-position: center;
    border-radius: 16px;
  }
  .thank-you {
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    color: #fff;
    margin: 0;
  }
  .support {
    margin: 8px 0 24px 0;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #1e1e26;
  }

  .sign-up {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #1e1e26;
    margin: 0 0 4px 0;
  }

  .form-part {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  .email-field {
    border: 1px solid #d2d2d4 !important;
    padding: 12px 16px;
    border-radius: 8px;
    color: #78787d;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    width: 200px;
  }

  .sign-up-cta {
    background: #22bbb0;
    box-shadow: 0 4px 12px 0px #00000014;
    padding: 12px 16px;
    border-radius: 40px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    white-space: nowrap;
  }
  .error-text {
    color: #ff0000;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  @media (min-width: 992px) {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
    padding: 32px 0;
    .sign-up-inner-container {
      background-image: url("../../../../../src/assets/images/thank-you-ds-card.png");
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 32px 100px;
      gap: 50px;
    }
    .thank-you {
      font-size: 24px;
      max-width: 390px;
      width: 100%;
    }
    .support {
      font-size: 18px;
      margin: 8px 0 0 0;
    }
    .sign-up {
      margin: 0 0 8px 0;
      font-size: 16px;
    }
    .email-field {
      border-radius: 12px;
      font-size: 16px;
      width: 250px;
    }
    .sign-up-cta {
      font-size: 16px;
    }
    .error-text {
      font-size: 14px;
    }
  }
}
