.specific-news-page{

    
    .main-content{
        margin: 20px auto;
        @media screen and (min-width:991px){
            width:89%;
            margin: 120px auto;
        }

        @media screen and (min-width:768px){
            margin: 120px auto;
        }
        
        .main-container{
            display:flex;
            flex-direction: row;
            align-self: center;
            gap: 50px;
            @media screen and (max-width:768px) {
                flex-direction: column;
                padding-right: 0px !important;
                padding-left: 0px !important;
            }
            .main-news-section{
                @media screen and (min-width:991px) {
                    width: 70%;
                    
                }
                @media screen and (max-width:991px){
                    margin-top: -50px;
                    align-items: center;
                   
                }
                display: flex;
                flex-direction: column;
                h2{
                   width: 90%;
                   font-size: 24px;
                   font-weight: 700;
                   line-height: 36px;
                   color: var(--black-100, #1e1e26);
                   font-family: "Ubuntu", sans-serif;
                   font-style: normal;
                   margin-bottom: 0;
                 }
                 h1{
                    width: 100%;
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 36px;
                    color: var(--black-100, #1e1e26);
                    font-family: "Ubuntu", sans-serif;
                    font-style: normal;
                    margin-bottom: 0;
                    @media screen and (max-width:768px) {
                        padding-top: 50px;
                    }
                  }
                  h3{
                    font-family: "Open Sans", sans-serif;
                    font-style: normal;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 32px;
                    color: var(--black-100, #1e1e26);
                    margin-bottom: 0;
                  }
                  h4{
                    font-family: "Open Sans", sans-serif;
                    font-style: normal;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 32px;
                    color: var(--black-100, #1e1e26);
                    margin-bottom: 0;
                  }
                img{
                    align-items: center;
                    width:80%;
                    height: 450px;
                    border-radius: 3%;
                    object-fit: cover;
                    @media screen and (max-width: 991px) {
                    height: 260px;  
                    }
                }
                  p,ul>li{
                    color: var(--black-100, #1e1e26);
                    font-family: 'Open Sans', sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                
                  }

                strong{
                    font-family: "Open Sans", sans-serif;
                    font-style: normal;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    color: var(--black-100, #1e1e26);
                }
               
                .main-news-content{
                    width: 80%;
                    
                    p{
                        text-align: justify;
                        img{
                            align-items: center;
                            width:100%;
                            border-radius: 3%;
                            object-fit: cover;
                        }
                    }
                    // span{

                    // }
                }

            }
            .extra-news-section{
                width: 20%;
                @media screen and (max-width:991px) {
                    width: 100%;
                }

                .outer-main-card{
                    width: 328px;
                    padding: 32px 24px 32px 24px;
                    border-radius: 8px;
                    border: 1px;
                    border: 1px solid #D9D9D9;
                    margin: auto;
                }

                .inner-card{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .more-on-and-view-all{
                        display:flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .more-on{
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 32px;
                            text-align: left;
                            
                            @media screen and (max-width:768px){
                                
                            }

                        }
                        .view-all{
                            font-family: 'Open Sans',sans-serif;
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 28px;
                            text-align: right;
                            color: #00A095;

                            @media screen and (max-width:768px){
                                
                            }

                        }
                    }
                    .extra-news-outer{
                        display: flex;
                        flex-direction: column;
                        gap: 24px;
                        .extra-news-inner{
                            display: flex;
                            flex-direction: row;
                            gap: 8px;
                            width: 100%;
                            height: 111px;
                            .image-div{
                                width: 114px;
                                height: 110px;
                                border-radius: 8px;
                                img{
                                    width: 114px;
                                    height: 110px;
                                    border-radius: 8px;
                                }
                            }
                            .content-div{
                                display: flex;
                                flex-direction: column;
                                gap: 8px;

                                .head{
                                    font-family: 'Ubuntu',sans-serif;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 27px;
                                    text-align: left;
                                    text-align: justify;
                                    overflow-y: hidden;
                                }
                                .sourece-date{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-around;
                                    align-items: center;
                                    .source-image{
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        img{
                                            width: 20px;
                                            height: 20px;
                                            border-radius: 50%; 
                                        }
                                    }
                                    .dot{
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50%;
                                        background: #1E1E26;
                                    }
                                    .date{
                                        font-family: 'Open Sans',sans-serif;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 22px;
                                        color: #78787D;
                                    }
                                }
                            }


                        }
                    }
                }
            }

        }
    }
}