$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.offer-available-page {
  .main-content {
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #1e1e26;
      padding-top: 40px;
      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }
    .booking-trip-part {
      margin-top: 100px;
      margin-bottom: 270px;

      h2 {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #1e1e26;
      }
      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #78787d;
      }
    }
    .bookig-trip-state {
      background: #ffffff;
      border-bottom: 1px solid #e8e8e9;
      border-radius: 20px 20px 0px 0px;
      // padding: 16px 0 48px 0;
    }

    button {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      padding: 12px 56px;
      max-width: 343px;
      width: 100%;
      background: #22bbb0;
      border-radius: 100px;
    }
  }
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 1024px) {
  .offer-available-page {
    .main-content {
      padding-left: 70px;
      padding-right: 70px;
      .desktop-booking-trip {
        margin-top: 32px;
        h1 {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 48px;
          color: #1e1e26;
          margin-top: 40px;
        }
        .home {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #a5a5a8;
        }
        .middle {
            font-family: $font-family_1;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #a5a5a8;
          padding-left: 4px;
          padding-right: 4px;
        }
        .offer {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: #00a095;
        }
      }
      .booking-trip-part {
        margin-top: 170px;
        margin-bottom: 0px;
        img {
          width: 174.18px;
          height: 199.88px;
          margin: auto;
        }

        h2 {
          font-size: 16px;
          text-align: center;
        }
        p {
          font-size: 16px;
          text-align: center;
        }
      }
      button {
        max-width: 342px;
        width: 100%;
        padding: 16px 117px;
        margin-bottom: 164px;
        margin-top: 32px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .offer-available-page {
    .main-content {
      max-width: 1280px;
      width: 100%;
      margin: auto;
    }
  }
}
