@import '../../assets/styles/font_color_mixins';

.footer-component {
  // background-color: white;
  z-index: 4;
  position: absolute;
  width: 100%;
 


  .dark-side ,.dark-side-border-radius-none {
    background-color: $black-1E1E26;
    padding: 32px 64px;
    border-radius: 24px 24px 0px 0px
  }
  .dark-side-border-radius-none{
    border-radius: 0;
  }
  .light-side {
    background-color: $teal-22BBB0;
    padding: 10px 80px;
    .light-side-mobile-heading{
      font-family: 'Open Sans',sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 22px;
    }
  }

  .footerLink{
    color:white;
  }

  .subscription-input {
    .input-wrap {
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      padding: 8px;
      background-color: white;
    }

    .button-wrap {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
    }
  }
}

@media(max-width:1023px){
  .footer-component {
    .dark-side ,.dark-side-border-radius-none {
      padding: 16px;
    }
    .dark-side-border-radius-none{
      border-radius: 0;
    }
    .light-side {
      background-color: $teal-22BBB0;
      padding: 16px;
    }
  }
}
