@import '../../assets/styles/font_color_mixins';

.guest-user-booking {

  .guest-user-failuer-image {
    width: 142px;
    height: 143px;
    margin: 43px 0 24px;
  }

  .page-not-found-illustration {
    width: 177px;
    height: 125px;
    margin-top: 43px;
  }

  .name-label {
    padding: 16px 0 0 0;
  }

  .ticket-no {
    padding-top: 16px;
  }

  .booking-confirmation {
    margin-left: 56px;
  }

  .ticket-issue {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding: 16px 16px 33px 16px;
    color: #78787D;
    max-width: 330px;
    width: 100%;
    margin: 0 auto;
  }

  .guest-user-input-searches {
    max-width: 343px;
    width: 100%;
    margin: 0 auto;
  }

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    padding: 16px;
    width: 343px !important;
    border-radius: 40px;
    margin: 103px 0 16px 0;
    &:disabled{
      opacity: 0.6;
    }
  }

  .guest-user-guest-booking {
    color: #1e1e26;
    max-width: 343px;
    width: 100%;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    border: 1px solid #D2D2D4 !important;

  }

  .guest-user-label {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #78787D;
    margin: 16px 0 4px 0;
  }

  .validation-part {
    gap: 6px;
    margin: 4px 0 0 0;
  }

  .error-state-border {
    border: 1.5px solid #F15252 !important;
    font-weight: 600;
  }

  .error-msg {
    margin: 0;
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #F15252;

  }


  .sign-in {
    font-family: Open Sans, sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #78787D;

    span {
      color: #00A095;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }


    .ticket-no {
      font-size: 20px;
    }


  }

}