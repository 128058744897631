@import '../../assets/styles/font_color_mixins';
@import "_variable.scss";

.offer-list-page {

  width: 89%;
  margin: auto;

  // overflow-x: auto;

  // .offer-container {
  //   padding: 8px 10px;

  //   background: rgba(255, 255, 255, 0.4);
  //   /* White / 100 */

  //   border: 1.5px dashed #FFFFFF;
  //   backdrop-filter: blur(4px);
  //   /* Note: backdrop-filter has minimal browser support */

  //   border-radius: 8px;
  // }

  // .horizontal-bus-listing-illustration {
  //   width: 130px;
  // }

  // .local-green-medium-C3EBEA-bg {
  //   background-color: #C3EBEA40;
  // }

  // .bottom-nav {
  //   background-color: #1E1E26;
  //   box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
  //   border-radius: 16px 16px 0 0;
  // }

  // .green-button-border {
  //   border: 1px solid #7AD6D0;
  //   border-radius: 16px;
  //   padding: 12px 24px;
  //   width: fit-content;

  //   p {
  //     white-space: nowrap;
  //   }
  // }

  // .submit-button {
  //   background: #22BBB0;
  //   color: white;
  //   display: flex;
  //   align-items: center;
  //   border-radius: 100px;
  //   justify-content: center;
  //   @media screen and (min-width: 1023px) {
  //     min-width: 342px;
  //   }

  //   p {
  //     margin-bottom: 0;
  //   }
  // }

  // .breadcrumber{

  //   @media screen and (max-width: 1023px) {
  //     margin: 12px 12px !important;
  //   }
  //   @media screen and (min-width: 1024px) {
  //     margin: 20px 0px 0px 80px !important;
  //   }

  // }

 

  // .lucky-drawer-card {
  //   background-image: url($background-cdn + "assets/images/uckydraw-winner-homepage.png");
  //   background-repeat: repeat-x;
  //   background-position: left top;
  //   background-size: contain;
  //   background-color: #1E1E26;
  //   @media screen and (max-width: 1023px) {
  //     background-color: #212128 !important;
  //     border-radius: 16px;
  //     margin: 0 !important;
  //   }
  //   @media screen and (min-width: 1024px) {
  //     //margin-top: -16px;
  //     padding-top: 16px;
  //     border-radius: 16px;
  //   }
  // }

  // @media screen and (max-width: 1023px) {
  //   .navbar-wrapper {
  //     padding-bottom: 24px;
  //     background: #1E1E26;

  //   }
  //   .white-fold-padding {
  //     padding: 21px 16px 0
  //   }
  //   .button-border {
  //     border: 1px solid #1E1E26;
  //     border-radius: 100px;
  //     padding: 12px 24px;
  //     width: fit-content;

  //     p {
  //       white-space: nowrap;
  //     }
  //   }

  //   .super_offe {
  //     max-width: 77px !important;
  //     max-height: 77px !important;
  //   }

  //   .parent-card-1 {
  //     min-width: 100%;
  //     max-width: 100%;
  //     height: 100%;
  //     margin-bottom: 24px;
  //   }

  // }
  // @media screen and (min-width: 1024px) {
  //   .navbar-wrapper {
  //     padding-bottom: 24px;
  //     background: #1E1E26;

  //   }
  //   .navigation-tab {
  //     background: #1E1E26;
  //     box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  //     border-radius: 100px;
  //     padding: 16px 24px;
  //   }
  //   .white-fold-padding {

  //     display: flex;
  //     justify-content: center;
  //     padding: 24px 80px 64px 80px;

  //     .inner-div {
  //       width: 100%;
  //       max-width: 1400px;
  //     }
  //   }
  //   .button-border {
  //     border: 1px solid #7AD6D0;
  //     border-radius: 100px;
  //     padding: 12px 24px;
  //     width: fit-content;

  //     p {
  //       white-space: nowrap;
  //     }
  //   }

  //   .super_offe {
  //     max-width: 77px !important;
  //     max-height: 77px !important;
  //   }

  //   .parent-card-1 {
  //     min-width: 360px;
  //     max-width: 390px;
  //     height: 168px;
  //     margin-bottom: 24px;
  //   }

  // }

  // .g-28 {
  //   gap: 28px;
  // }

  // .offer-bottom-card {
  //   background: rgba(255, 255, 255, 0.6);
  //   backdrop-filter: blur(4px);
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  //   left: 0;
  // }

  // .offer-top-card {
  //   @media screen and (max-width: 1023px) {
  //     .card-offer {
  //       min-height: 136px;
  //       max-height: 139px;
  //     }
  //   }
  //   @media screen and (min-width: 1024px) {
  //     .card-offer {
  //       min-height: 656px;
  //       max-height: 659px;
  //     }
  //   }

  // }

  // .card-inner-wrap {

  //   background-repeat: no-repeat !important;
  //   background-size: 100% 100% !important;
  //   border-radius: 16px;
  //   overflow: hidden !important;
  //   padding-bottom: 40px;

  //   @media screen and (min-width: 1024px) {
  //     min-height: 181px;

  //     max-width: 100%;

  //   }

  //   @media screen and (max-width: 1023px) {
  //     min-height: 136px;

  //   }
  //   display: flex;
  //   flex-flow: column;

  //   justify-content: space-between;
  // }

  // @media screen and (min-width: 1024px) {
  //   //.card-offer {
  //   //  -ms-overflow-style: none;
  //   //  scrollbar-width: none;
  //   //  //cursor: grab;
  //   //  //height: 656px;
  //   //}
  //   //
  //   //.card-offer::-webkit-scrollbar {
  //   //  display: none;
  //   //}

  //   .card-offer {
  //     //display: block;

  //     .offer-text {
  //       font-family: 'Open Sans', sans-serif;
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 16px;
  //       line-height: 24px;
  //       color: #4B4B51;
  //       position: absolute;
  //       left: 128px;
  //       top: 40px;
  //     }

  //     .offer-amount {
  //       //font-family: 'Ubuntu', sans-serif;
  //       //font-style: normal;
  //       //font-weight: 700;
  //       //font-size: 20px;
  //       //line-height: 32px;
  //       color: #1E1E26;
  //     }

  //     .expires {
  //       //position: absolute;
  //       //bottom: 9px;
  //       //left: 16px;
  //       //font-family: 'Open Sans', sans-serif;
  //       //font-style: normal;
  //       //font-weight: 600;
  //       //font-size: 14px;
  //       //line-height: 22px;
  //       color: #4B4B51;
  //     }

  //     .icici-dank {
  //       //position: absolute;
  //       //right: 16px;
  //       //bottom: 9px;
  //     }

  //     .super_offe {
  //       //position: absolute;
  //       //left: 24px;
  //       //top: 18px;
  //       object-fit: contain;
  //       width: 85px;
  //     }

  //     .swiper-slide {
  //       //margin-left: 8px;
  //       width: 100% !important;
  //       max-width: 400px;
  //     }

  //     .slick-slide {
  //       width: 400px !important;

  //       div,
  //       div:focus-visible {
  //         margin-right: 10px;
  //         outline: none;
  //       }
  //     }

  //   }

  // }

  // @media screen and (max-width: 1023px) {
  //   .card-offer {
  //     padding: 0 16px;
  //     //display: block !important;

  //     .offer-text {
  //       font-family: 'Open Sans', sans-serif;
  //       font-style: normal;
  //       font-weight: 600;
  //       font-size: 16px;
  //       line-height: 24px;
  //       color: #4B4B51;
  //       position: absolute;
  //       left: 128px;
  //       top: 40px;
  //     }

  //     .offer-amount {
  //       //font-family: 'Ubuntu', sans-serif;
  //       //font-style: normal;
  //       //font-weight: 700;
  //       //font-size: 20px;
  //       //line-height: 32px;
  //       color: #1E1E26;
  //     }

  //     .expires {
  //       //position: absolute;
  //       //bottom: 9px;
  //       //left: 16px;
  //       //font-family: 'Open Sans', sans-serif;
  //       //font-style: normal;
  //       //font-weight: 600;
  //       //font-size: 14px;
  //       //line-height: 22px;
  //       color: #4B4B51;
  //     }

  //     .icici-dank {
  //       position: absolute;
  //       right: 16px;
  //       bottom: 9px;
  //     }

  //     .super_offe {
  //       //position: absolute;
  //       //left: 24px;
  //       //top: 18px;
  //       width: 60px;
  //       object-fit: contain;
  //     }

  //     .swiper-slide {
  //       //margin-left: 8px;
  //       width: 100% !important;
  //       max-width: 350px;

  //       div,
  //       div:focus-visible {
  //         margin-right: 10px;
  //         outline: none;
  //       }
  //     }

  //   }

  //   //.card-offer {
  //   //  display: block;
  //   //
  //   //  .offer-text {
  //   //    font-weight: 600 !important;
  //   //    font-size: 11px !important;
  //   //    line-height: 15px !important;
  //   //    top: 12px !important;
  //   //    width: 92px;
  //   //
  //   //    .offer-amount {
  //   //      font-weight: 700 !important;
  //   //      font-size: 18px !important;
  //   //      line-height: 21px !important;
  //   //    }
  //   //
  //   //  }
  //   //
  //   //  .super_offe {
  //   //    width: 58px !important;
  //   //    height: 65px !important;
  //   //  }
  //   //
  //   //  .icici-dank {
  //   //    bottom: 4px !important;
  //   //  }
  //   //
  //   //  .expires {
  //   //    font-weight: 600 !important;
  //   //    font-size: 9px !important;
  //   //    line-height: 12px !important;
  //   //  }
  //   //
  //   //  .slick-slide {
  //   //    div {
  //   //      margin-right: 10px;
  //   //    }
  //   //  }
  //   //
  //   //  .card1, .card2, .card3 {
  //   //    max-width: fit-content;
  //   //    height: fit-content;
  //   //  }
  //   //}
  // }

   .card-listing-wrapper {

    display: grid;
    @media screen and (min-width: 700px) and (max-width: 1239px) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }
    @media screen and (min-width: 1240px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
    }

    @media screen and (max-width: 699px) {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
  }

  .offer-parent-card{
     width: 370px;
    aspect-ratio: 4/2;
    border: 1px solid #D2D2D4;
    border-radius: 16px;
    // width: 100%;
    // height: 100%;
    position: relative;
    
    overflow: hidden;
    @media screen and (max-width:739px) {
      width: 100%;
        aspect-ratio: 4/2.2;                        
    }
        img{
            width: 100% !important ;
            border-radius: 16px;
            height: 100%;
            object-fit: fill;
            
        }
        .overlap{
            display: flex;
            position: absolute;
            bottom: 0px;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            height: 65px;
            border-radius: 16px;
            padding: 12px 18px;
            box-shadow: 0px -2px 30px 0px #0000004D;
            // background: linear-gradient(90deg, #FEFBC7 0%, #FBF9D3 100%);
            @media screen and (max-width:739px) {
                height: 60px;
                border-radius: 12px 12px 0px 0px; 
                padding: 8px;                           
            }

            .desc{
                display: flex;
                flex-direction: column;

                .head{
                    font-family: 'Ubuntu',sans-serif;
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 0;
                    @media screen and (max-width:739px) {
                        font-size: 16px;
                        line-height: 22px;
                    }

                }
                .descrip{
                    font-family: 'Open Sans',sans-serif;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 0;
                    color: #4B4B51;

                    @media screen and (max-width:739px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }

            }
            .code-n-copy{

                display: flex;
                flex-direction: column;
                gap: 6px;
                .code{
                    margin-bottom: 0;
                    font-family: 'Open Sans', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: left;
                    @media screen and (max-width:992px) {
                      font-size: 10px;
                    }
                    

                }
                .copy-code-div{
                    width: fit-content;
                    // height: 40px;
                    padding: 6px ;
                    border-radius: 8px;
           
                    background: #FFFFFF66;
                    border: 1.5px dotted #FFFFFF;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;

                    img{
                      width: 18px;
                      height: 18px;
                      @media screen and (max-width:992px) {
                        width: 10px;
                        height: 10px;
                      }
                    }
                    p{
                      margin-bottom: 0;
                      font-family: 'Open Sans', sans-serif;
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 16px;
                      text-align: left;
                      @media screen and (max-width:992px) {
                        font-size: 10px;
                        line-height: 14px;
                      }
                    }

                }

            }
        }

    
}

  .offer-modal-component{
    .modal{
      width: 650px;
      @media screen and (max-width:768px) {
        width: 90%;
        margin-left: calc((100vw - 90vw) / 2) !important;
        margin-right: calc((100vw - 90vw) / 2) !important;
      }
      .modal-content{
        height: fit-content;
        min-height: fit-content;
        border-radius: 12px;
      }
      .modal-body{
        @media screen and (max-width:768px) {
          overflow-y: scroll;

        }
        .offer-modal-body{
          padding: 2rem;
         
          .close-icon-offer{
              position: absolute;
              top: 20px;
              right: 20px;
              cursor: pointer;
              @media screen and (max-width:450px){
                height: 16px;
              }
            
          }
          .upper-section{
            display:flex;
            flex-direction: column;
            gap: 12px;
            align-items: center;
            .offer-heading{
              font-family: 'Open Sans', sans-serif;
              font-size: 18px;
              font-weight: 700;
              line-height: 28px;
              text-align: center;
              margin-bottom: 0px;
            }
            .offer-sub-heading{
              font-family: 'Open Sans', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              text-align: center;
              margin-bottom: 0px;
            }
            .copy-coupon-div{
              width: 145px;
              height: 42px;
              padding: 10px 16px 10px 16px;
              border-radius: 8px;
              background: #E9F8F7;
              border: 1.5px dashed #22BBB0;
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            }
            .validity{
              font-family: 'Open Sans', sans-serif;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              margin-bottom: 0px;
            }
          }
          .tnc-div{
            margin-top: 20px;
            h3{
              font-family: 'Open Sans', sans-serif;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              margin-bottom: 0px;
              @media screen and (max-width:768px){
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
              }
            }
            ul{
              margin: 0;
              padding : 0;
              li{
                padding: 0;
                margin: 0;
                font-family: 'Open Sans', sans-serif;
                font-size: 15px;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                @media screen and (max-width:768px){
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    
    }
    
  }

}
