@import '../../assets/styles/font_color_mixins';

.custom-toast-component {
  //background: #FFFFFF;
  //border: 1px solid #F4F4F4;
  //box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  //border-radius: 16px;
  z-index: 1000000;

  .illustration-icon {

  }

  .heading {

  }

  .message {

  }
}

// this is for toast z index to be top of everything
#ct-container {
  z-index: 100000 !important;
}
