@import '../../assets/styles/font_color_mixins';

.coupon-list-component {
  .coupon-card {
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    border-radius: 16px;

    .vertical-text-wrapper {
      background: #4EC9C0;
      border-radius: 16px 0 0 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px;
      padding: 12px;
      p{
        transform: rotate(-90deg);
      }
    }
  }
}