@import "../../assets/styles/font_color_mixins";
@import "_variable.scss";

.profile-detail-page {

  background-color: $black-1E1E26;

  .white-fold {
    background-color: white;
    padding-top: 24px;
    border-radius: 28px 28px 0 0;
    margin-top: 0;
  }

  .profile-image-wrapper {
    position: relative;

    img {
      width: 64px;
      height: 64px;
      border-radius: 100%;
    }

    .profile-icon-triggerer {
      position: relative;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/icons/edit-icon-yellow-bg.png');

      #upload-profile-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    .edit-icon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }

  }

  .wallet-wrapper-box {
    width: 150px;
    height: 86px;
    padding: 12px 8px;
    border: 1px solid #A5A5A8;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
    border-radius: 12px;
    margin-left: 12px;
  }

  .outline-button {
    background: #FFFFFF;
    border: 1.5px solid #22BBB0;
    border-radius: 40px;
    padding: 10px 16px;
    height: fit-content;
  }

  .personal-details-wrapper {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
  }

}

.profile-detail-mobile {
  position: relative;

  .profile-image-wrapper {
    position: relative;
    margin: auto;
    width: fit-content;

    .profile-icon-triggerer {
      position: relative;
      width: 24px;
      height: 24px;
      background-image: url('../../assets/icons/edit-icon-yellow-bg.png');
      border-radius: 100%;

      #upload-profile-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    .edit-icon {
      position: absolute;
      bottom: 16px;
      right: 0;
      cursor: pointer;
      height: 24px;
      width: 24px;
      border-radius: 100%;
    }

  }

  .profile-image {
    width: 79px;
    height: 79px;
    border-radius: 100%;
    padding: 2px;

    outline: 0.5px solid rgba(75, 75, 81, 1);
    outline-offset: 12px;
  }

  .profile-detail-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
  }

  .user-profile {
    background: linear-gradient(90deg, #22BCB1 0%, #8FE9B0 100%);
  }

  .guest-profile {
    background: #4B4B51;
  }

  .mobile-number-pill {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    padding: 4px 8px;
    width: fit-content;
    margin: 0 auto;
  }

  .dark-fold {
    background: #1E1E26;
    padding: 24px 0 48px;
  }

  .light-fold {
    padding: 16px 0 0 0;
    margin-top: -16px;
    z-index: 1000;
    background-color: white;
    border-radius: 20px 20px 0 0;
  }

  .profile-wallet-box {
    border: 0.5px solid #4B4B51;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    border-radius: 100px;
    padding: 16px;
  }

}

.refer-and-earn-card {
  background-image: url($background-cdn + 'assets/images/refer-and-earn-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  padding: 32px 32px 0 32px;
  display: flex;
  justify-content: space-between;

  .submit-button {
    background-color: black;
    color: white;
    border-radius: 40px;
    padding: 8px 16px;
  }

  img {
    @media screen and (max-width: 1023px) {
      height: 123px;
    }
  }
  .refer-modal-component{

    // @media screen and (max-width:991px) {
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100% !important; 
    //   height: 100% !important;
    //   background: rgba(0, 0, 0, 0.5);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   z-index: 1000;
    // }
    .modal{
      width: 403px !important;
      margin: auto !important;
      @media screen and (max-width:991px){
        width: 300px !important;
        height: auto;
        margin-left: calc((100vw - 300px) / 2) !important;
        margin-right: calc((100vw - 300px) / 2) !important;
        
      }
      

      .refer-modal-body{
        padding: 3rem;
        @media screen and (max-width:768px){
          padding: 1rem;
          // position: relative;
          // z-index: 1001;
        }
        .close-icon-refer{
          position: absolute;
          top: 20px;
          right: 20px;
          cursor: pointer;
          @media screen and (max-width:768px){
            height: 16px;
          }
        }
        .refer-image{
          width: 241px;
          height: 221px;
          margin-bottom: 5px;
          @media screen and (max-width:450px){
            width: 190px;
            height: 190px;
          }
        }
        .refer-heading{
          font-family: 'Open Sans', sans-serif;
          font-size: 24px;
          font-weight: 700;
          line-height: 36px;
          text-align: center;
          color: #1E1E26;
          @media screen and (max-width:450px){
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
          }
  
        }
        .refer-desc{
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #78787D;
          margin-bottom: 16px;
          @media screen and (max-width:450px){
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
        .app-icons-div{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          @media screen and (max-width:768px){
            gap: 5px;
          }
          .playstore {
            width: 141px;
            cursor: pointer;
            height: 42px;
            @media screen and (max-width:768px){
              width: 131px;
            }
          }
        }
      }

    }
    
  }
}

.profile-personal-detail-component {

  @media screen and (max-width: 1023px) {

    .input-age {
      width: 80px;
    }

    .gender-box-right,
    .gender-box-left {
      padding: 16px 0;
      width: 100%;
      border: 1px solid #D2D2D4;
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }

    .gender-box-right {
      border-radius: 0px 12px 12px 0px;
    }

    .gender-box-left {
      border-radius: 12px 0px 0px 12px;
    }

    .active-tab {
      background: #D3F1EF;
      /* Teal / 100 */
      padding: 16px 0 !important;
      border: 1px solid #22BBB0  !important;
    }

    .personal-detail-wrapper {
      border: none;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      padding: 16px 32px;
      border-radius: 0;
    }

  }
  @media screen and (min-width: 1024px) {

    .input-age {
      width: 80px;
    }

    .gender-box-right,
    .gender-box-left {
      padding: 16px 0;
      width: 100%;
      border: 1px solid #D2D2D4;
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }

    .gender-box-right {
      border-radius: 0px 12px 12px 0px;
    }

    .gender-box-left {
      border-radius: 12px 0px 0px 12px;
    }

    .active-tab {
      background: #D3F1EF;
      /* Teal / 100 */

      border: 1px solid #22BBB0;
    }

    .personal-detail-wrapper {
      border: 1px solid #E8E8E9;
      border-radius: 24px;
      //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      padding: 24px;
    }

  }

}

.co-passenger-details-wrapper {
  .desktop-modal {
    .co-passenger-modal-wrapper {
      position: relative;
      width: 100%;

      .close-icon-wrap {
        position: absolute;
        top: 24px;
        right: 24px;
        z-index: 1000;
      }

    }

    .modal-body {
      padding: 0;
    }

    .modal {
      left: 50%;
      //transform: translateX(-50%);
      transform: translate(-50%, -50%);
      width: 75vw;
      //background: white;
      height: fit-content;

      top: 50%;
      z-index: 100000;
    }

    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      border-radius: 15px !important;
      overflow: hidden;
    }
  }

}

.profile-copassenger-detail-component {

  .delete-icon-wrap {
    padding: 8px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background: rgba(241, 82, 82, 0.08);
    cursor: pointer;
  }

  .edit-icon-wrap {
    padding: 8px;
    border-radius: 30px;
    background: #E9F8F7;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    .passenger-item-wrap {
      background: #FFFFFF;
      border: 1px solid #E8E8E9;
      border-radius: 10px;
      padding: 16px;
    }

  }
  @media screen and (min-width: 1024px) {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 24px;

    .passenger-item-wrap {
      padding: 24px 0 !important;
      border-bottom: 1px solid #E8E8E9;
    }

    .copassenger-detail-wrapper {
      border: 1px solid #E8E8E9;
      border-radius: 24px;
      //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
      padding: 24px;
    }
  }

}

.profile-checklist-component {
  .delete-icon-wrap {
    padding: 8px;
    height: 36px;
    width: 36px;
    border-radius: 100%;
    background: rgba(241, 82, 82, 0.08);
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {

    .checklist-item-wrap {

    }
    .checklist-item {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #D2D2D4;
      border-radius: 12px;
    }
    .flex-direction-column{
      flex-direction: column;
    }

  }
  @media screen and (min-width: 1024px) {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 24px;

    .checklist-item-wrap {
      // grid template 2 column
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
    }

    .checklist-item {
      background: #F0FAF9;
      border: 1px solid #D3F1EF;
      border-radius: 12px;
      padding: 16px;
    }
  }
}

.profile-card-collection {
  .profile-card-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 326px;
    gap: 14px;
  }

  .small-card {
    width: 163px;
  }

  .profile-card {
    border: 1px solid #E8E8E9;
    border-radius: 8px;
    padding: 16px;

    .card-image {
      width: 48px;
      height: 48px;
    }
  }

}

.support-feedback-card {
  background: linear-gradient(98.86deg, rgba(53, 213, 94, 0.09) 0%, rgba(34, 187, 176, 0.09) 30.5%, rgba(244, 195, 66, 0.09) 82.79%);
  border-radius: 16px;
  padding: 24px;
}

.profile-our-vision {
  padding: 16px 24px 0 24px;
  height: 25vh;
  background-image: url($background-cdn + "assets/images/profile-out-vision-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .bus-image {
    width: 102px;
  }
}

.profile-eco-contribution-card {
  background: #1E1E26;
  max-width: 1024px;
  width: 100%;
  padding: 16px 22px 16px 22px;
  border-radius: 14px;
  margin: 0 auto;

  .eco-contribution-title {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #FFFFFF;
  }

  .eco-contribution-share {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: #22BBB0;;
  }

  .share-image {
    margin-right: 8px;
  }

  .saved-population {
    max-width: 500px;
    width: 100%;
    padding: 10px 24px 10px 24px;
    border-radius: 16px;
    background: #FFFFFF0D;
    margin: 22px 0 0 0;
    position: relative;
  }

  .earned-world-image {
    position: absolute;
    right: 6px;
    bottom: 2px;

  }

  .saved-population-title {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFFCC;
  }

  .combined-shape {
    height: 17px;
    width: 17px;
  }

  .population-count {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: #F4F4F4;
  }

  .green-miles-text {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #78787D;
    width: 248px;
    padding: 12px;
    border-radius: 16px;
    box-shadow: 0 4px 16px 0 #00000033;
    background-color: #F4FCFB;
    position: absolute;
    bottom: -22px;
  }


  @media(min-width: 1024px) {
    padding: 24px 24px 32px 24px;
    border-radius: 24px;
    .eco-contribution-title {
      font-family: Ubuntu, sans-serif;
      font-size: 20px;
      line-height: 32px;
    }
    .eco-contribution-share {
      font-size: 18px;
    }

    .saved-population {
      max-width: 450px;
    }
    .combined-shape {
      height: 24px;
      width: 24px;
    }
    .population-count {
      font-family: Ubuntu, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      color: #22BBB0;

    }
    .saved-population-title {
      font-family: Ubuntu, sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
    }
  }


}


//green miles bottom tray
.profile-page-bottom-tray {
  hr {
    border: 1px solid #E8E8E9;
    margin: 0;
  }

  .content-section {
    padding: 0 !important;
    min-height: 14vh !important;
  }

  .world-image {
    height: 20px;
    width: 20px;
  }

  .green-miles-tray-content-part {
    padding: 17px 17px 0 17px;
  }

  .saved-pollution-title {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1E1E26;
  }

  .saved-pollution-subtext {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #78787D;
  }


}

.refer-modal-component{
  .modal{
    width: 403px !important;
    margin: auto !important;
    @media screen and (max-width:991px){
      width: 300px !important;
      height: auto;
      margin-left: calc((100vw - 300px) / 2) !important;
      margin-right: calc((100vw - 300px) / 2) !important;
      
    }
    

    .refer-modal-body{
      padding: 3rem;
      @media screen and (max-width:768px){
        padding: 1rem;
        // position: relative;
        // z-index: 1001;
      }
      .close-icon-refer{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        @media screen and (max-width:768px){
          height: 16px;
        }
      }
      .refer-image{
        width: 241px;
        height: 221px;
        margin-bottom: 5px;
        @media screen and (max-width:450px){
          width: 190px;
          height: 190px;
        }
      }
      .refer-heading{
        font-family: 'Open Sans', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        color: #1E1E26;
        @media screen and (max-width:450px){
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }

      }
      .refer-desc{
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        color: #78787D;
        margin-bottom: 16px;
        @media screen and (max-width:450px){
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
      .app-icons-div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        @media screen and (max-width:768px){
          gap: 5px;
        }
        .playstore {
          width: 141px;
          cursor: pointer;
          height: 42px;
          @media screen and (max-width:768px){
            width: 131px;
          }
        }
      }
    }

  }
  
}