@import '../../assets/styles/font_color_mixins';

.privacy-modal-content {

  .heading-list {
    background-color: #F8F8F9;
  }

  .heading-item {
    padding: 16px 40px;
    width: 100%;
  }

  .active-heading {
    background: #F0FAF9;
    border-left: 4px solid #22BBB0;
  }

  .terms-condition-details,
  .heading-list {
    max-height: 80vh;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .terms-condition-details {

    .title {
      text-align: center;
      font-style: normal;
      font-family: 'Open Sans', serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      padding-top: 40px;
      @media screen and (max-width: 1023px) {
        padding-top: 20px;
        font-size: 26px;
      }
    }

    table, th, td {
      border: 2px solid black;
      text-align: center !important;
    }

    table {
      margin-top: 10px;
    }

    th {
      font-family: 'Open Sans', sans-serif;

      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E26;
      margin-bottom: 8px;
    }

    h2 {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #1E1E26;
      @media screen and (max-width: 1023px) {
        font-size: 26px;
      }
    }

    h3 {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      color: #1E1E26;
      margin-bottom: 16px;
      @media screen and (max-width: 1023px) {
        font-size: 24px;
        line-height: 44px;
      }
    }

    h4 {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #1E1E26;
      margin-bottom: 8px;
      @media screen and (max-width: 1023px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    p, li, td {
      font-family: 'Open Sans', sans-serif;
      text-align: justify;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #78787D;
      @media screen and (max-width: 1023px) {
        font-size: 14px;
        line-height: 22px;
      }
    }

    @media screen and (max-width: 1023px) {
      padding: 16px;
      th, td {
        font-size: 12px;
      }

    }

  }

  .modal-content {
    .modal-body {
      .privacy-policy-modal-content__text {
        //@include font-color-mixins($color: $gray-600);
      }
    }
  }
}