@import '../../../assets/styles/font_color_mixins';
@import "_variable.scss";

.cancel-booking-component,
.cancel-booking-payment,
.reschedule-booking-component,
.modify-booking-component {

  textarea {
    background-color: transparent;
  }

  .reschedule-again{
    max-width: 650px;
    width: 100%;
    margin: 60px auto;
    padding: 0 22px;
  }

  .cta-part{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    @media(max-width: 1024px){
      flex-direction: column;
    }
  }
  .date-input {
    max-width: 343px;
    width: 100%;
  }

  .mobile-calendar-wrapper {
    width: 100%;
    //height: 330px;
    .calendar-wrapper {
      position: relative;
    }

    .react-calendar {

      width: 100%;
      box-shadow: none;
      margin: auto;
      max-width: 350px;
    }
  }

  .submit-button {
    background: #22BBB0;
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    color: white;
    min-width: 216px;
    border-radius: 40px;
  }

  @media screen and (max-width: 1024px) {

    .padding-top-side-local {
      padding: 16px 16px 0;
    }
    .padding-x-local {
      padding: 0 16px;
    }
    .refund-policy-wrap {

    }
    .fare-wrap {
      padding: 16px 24px;
      background-image: url($background-cdn + "assets/images/fare-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    .padding-top-side-local {
      padding: 40px 40px 0;
    }
    .padding-x-local {
      padding: 0 40px;
    }
    .refund-policy-wrap {
      //padding: 16px 20px !important;
      //background: #F4F4F4 !important;
      //border: 1px solid #D2D2D4;
      //border-radius: 8px;
    }

    .cannot-reschuedule-illustration{

      @media screen and (max-width: 1024px) {
        width:218px
      }
      width: 180px;
    }
  }

  .grey-area {
    background-color: #F8F8F9;
  }

  .green-area {
    background: #F0FAF9;
  }
}

.cancel-booking-payment {
  .fare-wrap {
    padding: 16px 24px;
    background-image: url($background-cdn + "assets/images/fare-bg.png");
    background-repeat: repeat-x;
    background-size: 50% 100%;
  }
}

.modify-booking-component{
  .tab-wrap{
    div{
      text-align: center;
      width: 175px !important;
    }
  }
  
 
}