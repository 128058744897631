$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;
.faq-details-page {
  .main-content {
    .title {
      padding-left: 16px;
      padding-right: 16px;
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: #1e1e26;
    }

    .home {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #a5a5a8;
    }
    .support {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #00a095;
    }
    .ques-ans-display {
      h2 {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4b4b51;
      }
      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #4b4b51;
      }
    }
  }
}

@media (min-width: 1024px) {
  .faq-details-page {
    .main-content {
      padding-left: 70px;
      padding-right: 70px;
    }
  }
}
@media (min-width: 1280px) {
  .faq-details-page {
    .main-content {
      max-width: 1280px;
      width: 100%;
      margin: auto;
    }
  }
}
