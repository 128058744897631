@import '../../assets/styles/font_color_mixins';

.page-not-found {


  .page-not-found-illustration{
    width: 100%;
  }

  .submit-button{
    background-color: $teal-22BBB0;
    color: white;
    padding: 16px;
    width: 378px;
    border-radius: 40px;
  }

  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
  @media screen and (min-width: 1024px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    //.white-fold-wrapper{
    //  max-width: 1144px;
    //  display: flex;
    //  justify-content: center;
    //}
    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 64px 32px 0;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }


    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }
}