.AnyPagefaq{
    width: 89%;
    margin: auto;
    margin-bottom: 30px;

    .title{
        font-family: 'Ubuntu',sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;

        @media screen and (max-width:1024px){
            font-family: 'Ubuntu',sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 32px;
            text-align: left;

        }

    }
    .accordion{
    display:flex;
    gap:16px;
    flex-direction: column;
    @media screen and (max-width:768px){
      height: 40vh;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: #22BBB0 #f1f1f1;
    }
    .accordion-item{
        box-shadow: 0px 4px 16px 0px #00000033;
        border: 1px solid #FFFFFF;
        border-radius: 8px;

        .accordion-header{
          .accordion-button{
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color:#1E1E26;
    
            @media screen and (max-width: 991px) {
              font-weight: 600;
              font-size: 16px;
              line-height: 26px;
            }
            
    
          }
    
          .accordion-button:not(.collapsed) {
            background-color: #FFFFFF;
            color:#1E1E26;
          }
          .accordion-button:not(.collapsed)::after {
            background-image: url('../../assets/icons/downArrow-green.svg');
            transform: rotate(0);
            
          }
          .accordion-button::after {
            background-image: url('../../assets/icons/downArrow-green.svg');
            transform: rotate(-180deg);
            
          }
    
        }
        .accordion-body{
          font-family: 'Open Sans', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;

          color: var(--Masters-Union-Secondary-Colors-Black-Black-300, #5B5B5B);

        //   h2{
        //     width: 90%;
        //     font-size: 24px;
        //     font-weight: 700;
        //     line-height: 36px;
        //     color: var(--black-100, #1e1e26);
        //     font-family: "Ubuntu", sans-serif;
        //     font-style: normal;
        //     margin-bottom: 0;
        //   }
        //   h1{
        //      width: 80%;
        //      font-size: 28px;
        //      font-weight: 700;
        //      line-height: 36px;
        //      color: var(--black-100, #1e1e26);
        //      font-family: "Ubuntu", sans-serif;
        //      font-style: normal;
        //      margin-bottom: 0;
        //      @media screen and (max-width:768px) {
        //          padding-top: 50px;
        //      }
        //    }
        //    h3{
        //      font-family: "Open Sans", sans-serif;
        //      font-style: normal;
        //      font-size: 20px;
        //      font-weight: 600;
        //      line-height: 32px;
        //      color: var(--black-100, #1e1e26);
        //      margin-bottom: 0;
        //    }
        //    h4{
        //      font-family: "Open Sans", sans-serif;
        //      font-style: normal;
        //      font-size: 18px;
        //      font-weight: 600;
        //      line-height: 32px;
        //      color: var(--black-100, #1e1e26);
        //      margin-bottom: 0;
        //    }
        //    p,ul>li{
        //      color: var(--black-100, #1e1e26);
        //      font-family: 'Open Sans', sans-serif;
        //      font-size: 15px;
        //      font-style: normal;
        //      font-weight: 400;
        //      line-height: 24px;
         
        //    }
  
        //  strong{
        //      font-family: "Open Sans", sans-serif;
        //      font-style: normal;
        //      font-size: 16px;
        //      font-weight: 700;
        //      line-height: 26px;
        //      color: var(--black-100, #1e1e26);
        //  }
          
    
        }
      }

    }

}