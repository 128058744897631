@import '../../assets/styles/font_color_mixins';

.change-booking-modal-component {

  .padding-top-side-40{
    padding: 40px 40px 0;
  }
  .padding-x-40{
    padding: 0 40px;
  }

  .modal-dialog{
    margin: auto;
    width: 100%;
  }
  .modal-content {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 0;
    height: 80vh;
    overflow-y: auto;


  }
  & ::-webkit-scrollbar {
    width: 6px;
    height: 88px;
    padding-left: 16px;
  }

  /* Track */
  & ::-webkit-scrollbar-track {
    padding-left: 16px;
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
  }

  /* Handle */
  & ::-webkit-scrollbar-thumb {
    background: #D2D2D4;
    border-radius: 14px;

  }

  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover,
  & ::-webkit-scrollbar-thumb:active {
    background: #D2D2D4;
  }

  .active-tab-local {
    padding: 16px;
    background: #E9F8F7;
    border: 1.5px solid #22BBB0;
    border-radius: 12px;
    width: 176px;
    text-align: center;
    p{
        color: #22BBB0;
    }
  }

  .inactive-tab-local {
    padding: 16px;
    border: 1px solid #D2D2D4;
    border-radius: 12px;
    width: 176px;
    text-align: center;
    p{
      opacity: 0.6;
    }
  }
}