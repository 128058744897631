.k2k-landing-page{

    .k2k-main-content{
        display: flex;
        flex-direction: column;
        margin: auto;
        @media screen and (min-width:1024px) {
            margin-top: 100px !important;
        }

    }
}