@import '../../assets/styles/font_color_mixins';

.change-booking-page {
  .padding-top-side-40 {
    padding: 40px 40px 0;
  }

  .padding-x-40 {
    padding: 0 40px;
  }

  .active-tab-local {
    padding: 16px;
    background: #E9F8F7;
    border: 1.5px solid #22BBB0;
    border-radius: 12px;
    width: 100%;
    text-align: center;

    p {
      color: #22BBB0;
    }
  }

  .inactive-tab-local {
    padding: 16px;
    border: 1px solid #D2D2D4;
    border-radius: 12px;
    width: 100%;
    text-align: center;

    p {
      opacity: 0.6;
    }
  }

  .payment-type-modal {
    .modal-dialog {
      position: relative;
      height: fit-content;
    }

    .modal-content {
      max-height: 70vh;
      height: fit-content;
      min-height: 20vh;
      //overflow-y: auto;
    }

    .header-section {
      padding: 8px 0 16px;
      border-bottom: 1px solid #E8E8E9;
    }

    .modal-content {;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 0px 0px !important;
    }

    .modal-dialog {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 20px 20px 0px 0px !important;
    }

    .content-section {
      max-height: 60vh;
      height: fit-content;
      min-height: 20vh;
      overflow-y: auto;
    }

    .closing-icon {
      position: absolute;
      top: -60px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      background-color: black;
      border-radius: 100%;
      padding: 16px;
      z-index: 10000;
    }

  }

}

.refund-success {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 70vh;
  align-items: center;
  padding: 16px;

  .submit-button {
    background: #22BBB0;
    padding: 16px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    color: white;
    width: 216px;
    border-radius: 40px;
  }

  @media screen and (max-width: 1024px) {
    .success-illustration {
      width: 218px;
      height: 218px;
      margin: auto;
    }
  }

  @media screen and (min-width: 1023px) {
    .success-illustration {
      width: 272px;
      height: 272px;
      margin: auto;
    }
  }

}