@import '../../assets/styles/font_color_mixins';

.mobile-subscreen-layout {

  .back-arrow {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
  }
  .dark-fold {
    background: #1E1E26;
    padding: 24px 0 48px ;
  }

  .light-fold {
    padding: 16px 0 0px 0;
    margin-top: -16px;
    z-index: 1000;
    background-color: white;
    border-radius: 20px 20px 0 0;
  }

}