@import '../../assets/styles/font_color_mixins';


.qr-code-modal-component{

  .modal-dialog{
    margin: auto;
  }
  .content-area{
    padding: 24px;
  }
}