.k2k-Banner {
    background: #1E1E26;
    background-image: url('https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/bannerbg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: 0 0 60px 0;
    @media screen and (min-width:991px) {
    padding: 50px 0;
    height: 492px;
    }
    @media screen and (max-width:991px) {
       margin-top: -17px;
       height: 300px;
       background-image: url('https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/03_K2K/MWeb%20Header%20Image.jpg');

      }
    .main-container {
      width: 89%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  
      .bread-crumb {
        span {
          color: rgba(255, 255, 255, 0.6);
          font-weight: 600;
          font-size: 14px;
          font-family: "Open Sans","sans-serif","Arial";
  
          &.active {
            color: #22BBB0;
            font-weight: 700;
          }
        }
      }
  
      .left-part {
        width: 100%;
        text-align: center;
        position: relative;
        top: 0;

  
        h1 {
          font-family: 'Ubuntu',sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 60px;
          color: #fff;
          margin: 50px 0 24px;
          @media screen and (max-width:991px) {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            margin: 24px 0 24px;
          }
        }
  
        h2 {
          font-family: 'Open Sans',sans-serif;
          font-size: 18px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
        }
  
      }
  
      .right-part {
        width: 100%;
        text-align: center;
        img{
          width: 50%;
          height: 50%;
        }
      }
  
  
  
    }
  }