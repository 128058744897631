.auth-modal {

  .otp-input-wrapper {
    
    .otp-input-style-active,
    .otp-input-style-error,
    .otp-input-style {

      // border: 1px solid rgba(0, 0, 0, 0.3) !important;
      
        width: 48px !important;
        height: 56px !important;
        border-radius: 12px;
        border: 1px solid  #1E1E26 !important;
        background: var(--black-03, #F8F8F9);
     
      
    }
    div {gap:8px;}

  }

  @media screen and (max-width: 1024px) {
    .modal {
      left: 0;
      width: 100%;
      z-index: 100000;
    }

    .modal-dialog {
      margin: 0;
      height: 100%;
    }

    .modal-content {
      border-radius: 15px;
      overflow: hidden;
      height: 100%;
    }
  }

  @media screen and (min-width: 1024px) {

    .modal {
      left: 50%;
      //transform: translateX(-50%);
      transform: translate(-50%, -50%);
      width: 75vw;
      //background: white;
      height: fit-content;

      top: 50%;
      z-index: 100000;
    }

    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      border-radius: 15px !important;
      overflow: hidden;
    }
  }

  .modal-body {
    padding: 0;
  }

}

.feedback-reminder-modal {
  .close-icon {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;

  }
}