@import '../../assets/styles/font_color_mixins';

//green miles bottom tray
.green-miles-bottom-tray {
  hr {
    border: 1px solid #E8E8E9;
    margin: 0;
  }

  .content-section {
    padding: 0 !important;
  }

  .world-image {
    height: 20px;
    width: 20px;
  }

  .green-miles-tray-content-part {
    padding: 16px 16px 16px 16px;
  }

  .bottom-tray-mobile-component .content-section {
    min-height: 14vh !important;
  }

  .saved-pollution-title {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1E1E26;
  }

  .saved-pollution-subtext {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #78787D;

  }
}

.confirmation-page {

  .horizontal-bus-listing-illustration {
    width: 130px;
  }

  .local-green-medium-C3EBEA-bg {
    background-color: #C3EBEA40;
  }

  .bottom-nav {
    background-color: #1E1E26;
    box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px 16px 0px 0px;
  }

  .green-button-border {
    border: 1px solid #7AD6D0;
    border-radius: 16px;
    padding: 12px 24px;
    width: fit-content;

    p {
      white-space: nowrap;
    }
  }

  .submit-button {
    background: #22BBB0;
    color: white;
    display: flex;
    align-items: center;
    border-radius: 100px;

    p {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }
    .white-fold-padding {
      padding: 21px 16px 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }


  }
  @media screen and (min-width: 1024px) {
    .green-miles-part {
      border: 1px solid #D2D2D4;
      width: 371px;
      border-radius: 20px;
      margin: 32px 0 16px 0;
      position: relative;

      .green-miles-image {
        max-width: 371px;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }

      .green-miles-data {
        padding: 24px;
      }

      .return-button{
        width: 332px;
        height: 60px;
        background: #22BBB0;
        border-radius: 40px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #FFFFFF;
      }
      .green-miles-success {
        font-family: Ubuntu, sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        color: #1e1e26;
      }

      .combined-shape-image-part {
        height: 20px;
        width: 20px;
        margin-left: 6px;
      }

      .green-miles-content {
        padding: 14px 0 0 0;
      }

      .green-miles-earn {
        font-family: Open Sans, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        color: #78787d;
      }

      .green-miles-earn-am {
        font-family: Ubuntu, sans-serif;
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        color: #4B4B51;
      }
      .green-miles-earn-strong {
        font-family: Ubuntu, sans-serif;
        font-size: 17px;
        font-weight: 700;
        line-height: 24px;
        color: #78787d;
      }

      p {
        margin: 0;
      }

      .green-miles-info {
        max-width: 253px;
        width: 100%;
        padding: 12px 17px 12px 12px;
        border-radius: 16px;
        background: #F4FCFB;
        box-shadow: 0 4px 16px 0 #00000033;
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #78787D;
        position: absolute;
        bottom: -90px;
        right: -40px;


      }


    }


    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;

    }

    .navigation-tab {
      background: #1E1E26;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-radius: 100px;
      padding: 16px 24px;
    }

    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 64px 32px 0;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }

    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }

  }

}

@media(max-width: 1023px) {
  .green-miles-part {
    border: 1px solid #D2D2D4;
    max-width: 343px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto 32px auto;

    .green-miles-image {
      max-width: 371px;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    .green-miles-data {
      padding: 24px 20px 28px 20px;
    }

    .return-button{
      width: 311px;
      height: 56px;
      background: #22BBB0;
      border-radius: 40px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 24px;
    }

    .combined-shape-image-part {
      height: 20px;
      width: 20px;
      margin-left: 6px;
    }


    .green-miles-success {
      font-family: Ubuntu, sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      color: #1e1e26;
    }

    .green-miles-content {
      padding: 14px 0 0 0;
    }

    .green-miles-earn {
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #78787d;
    }

    .green-miles-earn-am {
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #4B4B51;

    }
    .green-miles-earn-strong {
      font-family: Ubuntu, sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: #78787d;

    }

    p {
      margin: 0;
    }


  }
}


.receive-by-ticket {
  background-color: #F8F8F9;
  padding: 16px 19px 18px 16px;
  border-bottom: 1px solid #D2D2D4;

}

.ticet-confirmation {
  max-width: 800px;
  width: 100%;
}

.receive-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #1E1E26;
}

.social-icons-name {
  gap: 6px;
  padding: 10px 16px;
  background: #FFFFFF;
  border: 1px solid #D2D2D4;
  border-radius: 12px;
}

.social-name {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #1E1E26;
}

.down-load-invoice {
  background: #FFFFFF;
  border: 1px solid #22BBB0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 26px;

  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #00A095;
  }
}


@media(min-width: 1024px) {
  .receive-by-ticket {
    border-radius: 16px;
    padding: 26px 19px 24px 16px;

    border-bottom: none;
  }
  .ticet-confirmation {
    max-width: 391px;
    margin-bottom: 32px;
  }
  .receive-title {
    font-size: 18px;
    line-height: 28px;
    color: #78787D;
    margin: 0px;
    padding-bottom: 16px;
  }
  .social-icons-name {
    gap: 6px;
  }
}


//green miles bottom tray styles
.green-miles-bottom-tay {
  hr {
    border: 1px solid #E8E8E9;
    margin: 0;
  }

  .content-section {
    padding: 0 !important;
  }

  .world-image {
    height: 20px;
    width: 20px;
  }

  .green-miles-tray-content-part {
    padding: 24px 0 0 0;
  }

  .saved-pollution-title {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #1E1E26;
  }

  .saved-pollution-subtext {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #78787D;

  }
}


