@import "../../assets/styles/font_color_mixins";
@import "_variable.scss";

@media screen and (max-width: 1023px) {
  .auth-component {
    position: relative;
    border-radius: 0 !important;

    .closing-button {
      position: absolute;
      top: 40px;
      right: 48px;
      background-color: white;
      border-radius: 100%;
      z-index: 1000;
      cursor: pointer;
    }

    .content-area {
      overflow-y: auto;
      max-height: 90vh;
      min-height: 60vh;
    }

    .active-tab {
      //text-decoration: underline;
      color: $teal-22BBB0;
    }

    .in-active-tab {
      text-decoration: none;
      color: $grey-53565A;
    }

    .auth-display-img-wrap {
      background-image: url($background-cdn + "assets/images/auth-display-img.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .auth-display-img {
        width: 100%;

      }
    }

    .submit-button {
      border-radius: 100px;
      padding: 12px 24px;
      color: white;
    }

    .left-gradiant-line {
      width: 100%;
      height: 1px;
      //border: 1px solid;
      //border-image-source: ;
      background: linear-gradient(270deg, rgba(30, 30, 38, 0.2) 0%, rgba(30, 30, 38, 0) 119.38%);
    }

    .right-gradiant-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(30, 30, 38, 0) -35%, rgba(30, 30, 38, 0.2) 100%);
    }

    .google-wrap {
      text-align: center;

      button {
        justify-content: center !important;
        border-radius: 12px !important;
        background-color: #F8F8F9 !important;
        //width: fit-content;
        padding: 20px 0 !important;
        width: 279px !important;
        box-shadow: none !important;

        div {
          background-color: transparent !important;
        }
      }
    }

    .tab-underline {
      background-color: $teal-22BBB0;
      height: 2px;
      width: 100%;
    }

    .login-bg {
      background-color: #1E1E26;
      background-image: url($background-cdn + "assets/images/login-bg-greenlight.png");
      background-position: top right;
      background-repeat: no-repeat;
      padding: 48px 24px 64px;
      //height: 100px;
    }

    .bottom-padding-section {
      background: #FFFFFF;
      border-radius: 24px 24px 0 0;
      margin-top: -16px;
      z-index: 1000;
      padding: 24px 32px;
    }

    .mobile-login-illustration-bg {
      background-image: url($background-cdn + "assets/images/login-mobile-illustation.png");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}


@media screen and (min-width: 1024px) {
  .auth-component {
    position: relative;
    border-radius: 15px !important;
    overflow: hidden;

    .closing-button {
      position: absolute;
      top: 40px;
      right: 48px;
      background-color: white;
      border-radius: 100%;
      z-index: 1000;
      cursor: pointer;
    }

    .content-area {
      overflow-y: auto;
      max-height: 90vh;
      min-height: 60vh;
    }

    .active-tab {
      //text-decoration: underline;
      color: $teal-22BBB0;
    }

    .in-active-tab {
      text-decoration: none;
      color: $grey-53565A;
    }

    .auth-display-img-wrap {
      background-image: url($background-cdn + "assets/images/login_form_Img.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .auth-display-img {
        width: 100%;

      }
    }

    .submit-button {
      border-radius: 100px;
      padding: 12px 24px;
      color: white;
    }

    .left-gradiant-line {
      width: 100%;
      height: 1px;
      //border: 1px solid;
      //border-image-source: ;
      background: linear-gradient(270deg, rgba(30, 30, 38, 0.2) 0%, rgba(30, 30, 38, 0) 119.38%);
    }

    .right-gradiant-line {
      width: 100%;
      height: 1px;
      background: linear-gradient(270deg, rgba(30, 30, 38, 0) -35%, rgba(30, 30, 38, 0.2) 100%);
    }

    .google-wrap {
      text-align: center;

      button {
        justify-content: center !important;
        border-radius: 12px !important;
        background-color: #F8F8F9 !important;
        //width: fit-content;
        padding: 20px 0 !important;
        width: 279px !important;
        box-shadow: none !important;

        div {
          background-color: transparent !important;
        }
      }
    }

    .tab-underline {
      background-color: $teal-22BBB0;
      height: 2px;
      width: 100%;
    }

    .login-bg {
      background-color: #1E1E26;
      background-image: url($background-cdn + "assets/images/login-bg-greenlight.png");
      background-position: top right;
      background-repeat: no-repeat;
      padding: 48px 24px 64px;
      //height: 100px;
    }

    .bottom-padding-section {
      background: #FFFFFF;
      border-radius: 24px 24px 0 0;
      margin-top: -16px;
      z-index: 1000;
      padding: 24px 32px;
    }

    .mobile-login-illustration-bg {
      background-image: url($background-cdn + "assets/images/login-mobile-illustation.png");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
    }

    //guest -user booking styles
    .guest-booking-title {
      font-family: Ubuntu, sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      text-align: left;
      margin: 0;
      padding: 46px 0 0 0;
    }

    .guest-booking-subtitle {
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin: 0;
      color: #78787D;
      padding: 16px 0 38px 0;
    }

    .guest-user-guest-booking {
      color: #1e1e26;
      max-width: 477px;
      width: 100%;
      padding: 12px 16px 12px 16px;
      border-radius: 8px;
      border: 1px solid #D2D2D4 !important;
      font-size: 18px;
    }

    .guest-user-label {
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #78787D;
      margin: 16px 0 8px 0;
    }

    .validation-part {
      gap: 6px;
      margin: 4px 0 0 0;
    }

    .error-state-border {
      border: 1.5px solid #F15252 !important;
      //color: #F15252 !important;
      font-weight: 600;
    }

    .error-msg {
      margin: 0;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #F15252;
    }

    .guest-submit-button {
      max-width: 391px;
      width: 100%;
      padding: 16px;
      border-radius: 40px;
      background: #22BBB0;
      box-shadow: 0 4px 12px 0 #00000014;
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      color: #FFFFFF;
      margin: 40px 0 0 0;
      &:disabled{
        opacity: 0.6;
      }
    }

    .sign-in {
      //styleName: Body 1/Web/16/Bold;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #78787D;
      padding: 16px 0 0 0;

      span {
        font-weight: 700;
        color: #00A095;
        cursor: pointer;
      }
    }

    .guest-user-failure {
      img {
        margin: 41px 0 23px 0;
      }
    }


  }
}