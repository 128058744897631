@import './src/assets/styles/font_color_mixins';

.checklist-name-widget {
  .desktop-edit-checklist-modal {
    .submit-button {
      background-color: $teal-22BBB0;
      color: white;
      border-radius: 100px;
      padding: 16px 48px;
    }

    .create-checklist-content {
      padding: 40px;
      position: relative;

      .close-icon {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 0;
    }

    .modal {
      left: 50%;
      //transform: translateX(-50%);
      transform: translate(-50%, -50%);
      width: 75vw;
      //background: white;
      height: fit-content;

      top: 50%;
      z-index: 100000;
    }

    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      border-radius: 15px !important;
      overflow: hidden;
    }
  }
}

.checklist-data-widget {

  .desktop-data-checklist-modal {
  

    .data-checklist-content {
      padding: 40px;
      position: relative;
      height: fit-content;
      max-height: 70dvh;
      overflow: auto;

      .close-icon {
        position: absolute;
        right: 24px;
        top: 24px;
        cursor: pointer;
      }
      
    // scrollbar style
    
    }
    ::-webkit-scrollbar-thumb {
      background-color: #22BBB0;
      border-radius: 8px;
    }



    .modal-body {
      padding: 0;
    }

    .modal {
      left: 50%;
      //transform: translateX(-50%);
      transform: translate(-50%, -50%);
      width: 75vw;
      //background: white;
     
      top: 50%;
      z-index: 100000;
    }

    .modal-dialog {
      margin: 0;
    }

    .modal-content {
      border-radius: 15px !important;
      overflow: hidden;
    }
  }

  .add-list-box {
    background: #F8F8F9;
    padding: 16px;
    border: 1px solid #E8E8E9;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  select{
    appearance: none;
    width: fit-content;
    flex-shrink: 1;
  }
}