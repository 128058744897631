.download-app {
  .bottom-tray-mobile-component .header-section {
    padding: 0px;
    border-bottom: none;
  }
  .modal-body {
    padding: 0px;
  }
  .bottom-tray-mobile-component .content-section {
    padding-bottom: 24px !important;
    padding-top: 0px !important;
    // width: 100vw;
  }
  .modal-header {
    border-bottom: none;
  }
  .bottom-tray-mobile-component .modal-content {
    border-radius: 0 !important;
    width: 100vw;
  }
  .download-app-bus {
    text-align: center;
    margin: auto;
    img {
      border-radius: 20px 20px 0px 0px !important;
    }
  }
  .download-app-section {
    h2 {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #1e1e26;
      text-align: center;
      padding-top: 24px;
    }
    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #78787d;
      padding-bottom: 32px;
    }
  }
}
@media(min-width:1024px){
    .download-app{
        .bottom-tray-mobile-component .modal-content {
            border-radius: 0 !important;
            width: 75vw;
            margin-left: 12.5vw;
            margin-right: 12.5vw;

          }
    }

}
