.support-page{
  .title{
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #1E1E26;
    padding-top: 40px;
    padding-bottom: 20px;
    @media screen and (max-width: 1023px) {
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 26px;
    }
  }
  .main-content{
    text-align: center;
      padding: 20px 80px;
    @media screen and (max-width: 1023px) {
  padding: 16px;
    }
    .call-number{
      color:#22BBB0 ;
    }
    .whatsapp-parent{
      a{
        color:#22BBB0!important;
      }
    }
  }
}