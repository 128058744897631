.instagram-update {
  margin: 0 auto;
  padding: 32px 16px 0 16px;

  @media (min-width: 1024px) {
    max-width: 90%;
    width: 100%;
    padding: 80px 0 58px 0;
  }

  .title-part {
    padding: 0 0 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-family: "Ubuntu", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: normal;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #1e1e26;
      margin: 0;
      @media (min-width: 1024px) {
        font-size: 32px;
      }
    }
    @media (min-width: 1024px) {
      padding: 0 0 40px 0;
    }
  }

  .arrow {
    display: flex;
    img {
      cursor: pointer;
    }
  }

  .desc {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1e1e26;
    margin: 0;
    padding: 12px 0 0 0;
    @media (min-width: 1024px) {
      font-size: 20px;
      font-weight: 500;
      padding: 24px 0 0 0;
    }
  }

  .slider {
    .swiper {
      .swiper-wrapper {
        width: 90vw !important;
        height: 100% !important;
        .swiper-slide,
        .swiper-slide-active {
          img {
            width: 100%;
            height: 100%;
            border-radius: 14px;
            cursor: pointer;

            @media (min-width: 1024px) {
              border-radius: 20px;
            }
          }
        }
      }
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }
}
