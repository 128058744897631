.feature-card{
  display: flex;
  flex-direction: column;
  
  .grid-container {
    height: auto;
    display: grid;
    grid-template-columns: 481fr 381fr 277fr;
    gap: 10px; 
    @media screen and (max-width:1024px) {  
    display: flex;
    flex-direction: column;
    gap: 10px; 
    }

    .grid-item {
      height: auto;
      border: 1px solid #ccc;
      // padding: 20px;
      text-align: center;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 16px;
      cursor: pointer;
      img {
        border-radius: 16px;
        width: 100%; 
        height: 100%;
        object-fit: cover;
      }
    
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: row;
        gap: 10px; /* Space between grid items */
        min-height: 200px;
        
        
        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          gap: 10px; /* Space between grid items */
    
          img {
            width: 100%;
            height: 233px;
            display: block;
            margin-bottom: 10px;
          }
        }
    
        &:not(:first-child) {
          flex: 1; /* Ensure it takes the remaining space */
          //height: fit-content;
          img {
            border-radius: 16px;
            width: 45% !important;
            height: 35vw;
            object-fit: cover;
          }
        }
      }
    
      .mweb {
        // position: absolute;
        bottom: 0;
        background: linear-gradient(90deg, #000000 -100%, rgba(0, 0, 0, 0) 88.67%);
        background-position: center;
        background-size: fill;
        color: white;
        padding: 10px;
        height: 100%;
        justify-content: end;
        text-align: left;
        flex-direction: column;
        display: flex;
        justify-items: flex-end;
        border: transparent;
        width: 100%;
        border-radius: 16px;
        @media screen and (max-width: 1024px) {
          text-align: center;
          display: flex;
          flex-direction: column;
          position: absolute;
        }  
        h3 {
          margin: 15px 0 10px;
          font-family: 'Open Sans', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          text-align: left;
          @media screen and (max-width: 1024px) {
            margin: 0;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }
      
        p {
          width: max-content;
          height: auto;
          padding: 4px 8px 4px 8px;
          border-radius: 40px;
          color: #1E1E26;
          background-color: yellow;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          text-align: left;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
      
          @media screen and (max-width: 1024px) {
            visibility: hidden;
      
          }
        }
      
        a {
          display: inline-block;
          margin-top: 10px;
          color: #22BBB0;
          text-decoration: none;
          font-weight: 600;
          font-family: 'Open Sans',sans-serif;
          @media screen and (max-width: 1024px) {       
             font-size: 16px;
             font-weight: 500;
             line-height: 24px;
             text-align: left;
       
           }
        }
      }
    }

    .item2,.first-column {
    
      @media screen and (min-width:1024px) { 
      grid-row: span 2; 
      grid-column: span 1; 
      background-repeat: no-repeat;
      background-size: cover;
      img {
          border-radius: 16px;
          width: 20.8vw;
          object-fit: cover;
    }
  }
  @media screen and (max-width:768px){
    grid-row: span 2; 
    grid-column: span 1; 
    background-repeat: no-repeat;
    background-size: 100% 100%;

    img {
      border-radius: 16px;
      width: 100vw;
      object-fit: cover;
}
  }
    }

    .first-column{

      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between; 

       @media screen and (min-width:1024px) { 
        grid-row: span 2; 
        grid-column: span 1; 
  
        }


      .first-column-first-card{
        background-size: 100% 100%;
        border: 1px solid #ccc;
        // padding: 20px;
        text-align: center;
        width: 100%;
        position: relative;
        border-radius: 16px;
        height: 100%;
        cursor: pointer;
        background-repeat: no-repeat;
        @media screen and (max-width: 1024px) {
          min-height: 200px;
        }
        .mweb {
          position: absolute;
          bottom: 0%;
          // background: linear-gradient(90deg, #000000 -100%, rgba(0, 0, 0, 0) 88.67%);
          background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 88.67%);
          justify-content: end;
          background-position: center;
          background-size: cover;
          color: white;
          padding: 10px;
          height: 100%;
          text-align: left;
          flex-direction: column;
          display: flex;
          justify-items: flex-end;
          border: transparent;
          width: 100%;
          border-radius: 16px;
          @media screen and (max-width: 1024px) {
            text-align: center;
            display: flex;
            flex-direction: column;
          }  
          h3 {
            margin: 15px 0 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            text-align: left;
            @media screen and (max-width: 1024px) {
              margin: 0;
              font-family: 'Open Sans', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
            }
          }
        
          p {
            width: max-content;
            height: auto;
            padding: 4px 8px 4px 8px;
            border-radius: 40px;
            color: #1E1E26;
            background-color: yellow;
            font-family: 'Open Sans', sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 0;
        
            @media screen and (max-width: 1024px) {
              visibility: hidden;
        
            }
          }
        
          a {
            display: inline-block;
            margin-top: 10px;
            color: #22BBB0;
            text-decoration: none;
            font-weight: 600;
            font-family: 'Open Sans',sans-serif;
            @media screen and (max-width: 1024px) {       
               font-size: 16px;
               font-weight: 500;
               line-height: 24px;
               text-align: left;
         
             }
          }
        }
      }
    
   
    .card-newsletter{
      // background: rgb(250,193,196);
      background: linear-gradient(159deg, rgba(250,193,196,1) 22%, rgba(255,234,233,1) 77%);
      padding: 20px;
      height: auto;
      background-size: cover;
      text-align: center;
      width: 100%;
      position: relative;
      border-radius: 16px;
      @media screen and (max-width: 1024px) {
        text-align: center;
        display: flex;
        flex-direction: column;
      }
      h2 {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;

      }
      p {
        font-family: 'Open Sans',sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        }
      input {
        margin: 10px 0;
        border: 1px solid #D2D2D4;
        width: 313px;
        height: 48px;
        padding: 12px 16px 12px 16px;
        border-radius: 12px;
        @media screen and (max-width: 1024px) {
          width: 90%;
          margin: auto;
          margin-bottom: 10px;
        }
        
      }
      ::placeholder {
        color: #1E1E2666;
        opacity: .7; 
        font-family: 'Open Sans',sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      
      button {
        margin: 10px;
        box-shadow: 0px 4px 12px 0px #00000014;
        border: 1.5px solid #22BBB0 !important;
        color:#22BBB0;
        background: #FFFFFF;
        width: 123px;
        height: 48px;
        padding: 10px 16px 10px 16px;
        border-radius: 40px;
        @media screen and (max-width: 1024px) {
          width: 90%;
          margin: auto;
        }
      }
  }
    }
    .item5{
      background-image: url("https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Chennai%20Food%20blog/Chennai%20Famous%20Food_shutterstock_2476206729_484%20X%20371%20px.jpg");
      background-repeat: no-repeat;
      background-size: cover;
    }
      
  }
}
  
  