.feedback-reminder-component {

  .illustration-box {
    background: #FCC5C5;
    border-radius: 16px;
    @media screen and (max-width: 1024px) {
      padding: 16px;
      img{
        height: 118px;
        object-fit: contain;
      }
    }
    @media screen and (min-width: 1024px) {
      padding: 24px;

    }

  }

  .feedback-reminder-button {
    width: 100%;
    max-width: 343px;
  }
}