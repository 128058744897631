
// color vars
$yellow-F8EC4E :#F8EC4E;
$brown-F1D3B7:#F1D3B7;
$blue-light-F2FAF9:#F2FAF9;
$green-medium-C3EBEA:#C3EBEA;
$teal-22BBB0:#22BBB0;
$grey-53565A:#53565A;
$black-1E1E26:#1E1E26;
$gray-78787d:#78787d;


// colors class
.yellow-f8ec4e-bg{
background-color: $yellow-F8EC4E;
}
.brown-F1D3B7-bg{
  background-color: $brown-F1D3B7;
}
.blue-light-F2FAF9-bg{
  background-color: $blue-light-F2FAF9;
}
.green-medium-C3EBEA-bg{
  background-color: $green-medium-C3EBEA;
}
.teal-22BBB0-bg{
  background-color: $teal-22BBB0;
}
.grey-53565A-bg{
  background-color: #53565A;
}
.black-1E1E26-bg{
  background-color: #1E1E26;
}


