.blog-Page{
    
    .main-content{
        @media screen and (min-width:991px){
            width:89%;
            margin: 120px auto;
        }

        @media screen and (min-width:768px){
            margin: 120px auto;
        }
        
        .container{
            gap: 3%;
            row-gap: 30px;
            flex-wrap: wrap;
            
            .card{
                @media screen and (min-width:1024px){
                    width:30%;
                    height: fit-content;


                }
                .card-header{
                    @media screen and (min-width:1200px){
                        
                        //min-height: auto;
                        padding: .5rem .25rem;
                        font-family: "Open  Sans", sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: #1E1E26;
                        min-height: 89px;
                    }
                    @media screen and (min-width:1024px) and (max-width: 1199px){
                        
                        min-height: 120px;
                        padding: .5rem .25rem;
                        font-family: "Open  Sans", sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: #1E1E26;
                    }

                    @media screen and (max-width:1023px){
                        // min-height: 150px;
                        padding: .5rem .25rem;
                        font-family: "Open  Sans", sans-serif;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 24px;
                        color: #1E1E26;
                    }
                }
                .card-body{
                    .card-img-top{
                        @media screen and (min-width:1024px){
                            width:100%;
                            height:165px;
                            object-fit: cover;
                            // border-radius: 5%;
                        }
                    }
                    .card-text{
                        text-align: justify;
                        display: -webkit-box;
                        -webkit-line-clamp: 5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        @media screen and (min-width:1024px){
                            margin:15px 0px;
                            font-family: "Open  Sans", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: -.5px;
                        }
                         @media screen and (max-width:1024px){
                            margin:15px 0px;
                            font-family: "Open  Sans", sans-serif;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            letter-spacing: -.5px;
                        }
                        
                    }
                    .btn{
                        background-color: #22bbb0;
                        font-size: smaller;
                        font-weight: bold;
                        color: white;
                    }
                }
            }
        }
    }
}

.specific-blog-Page{

    
    .main-content{
        margin: 20px auto;
        width:89%;
        @media screen and (min-width:991px){
           
            margin: 120px auto;
        }

        @media screen and (min-width:768px){
            margin: 120px auto;
        }
        
        .containers{
            align-items: center;
            align-self: center;
            gap: 50px;
            @media screen and (max-width:768px) {
                padding-right: 0px !important;
                padding-left: 0px !important;
            }
            .main-blog-extra-blog{
                display: flex;
                
                @media screen and (min-width:992px) {
                    flex-direction: row;
                    justify-content: space-between;
                }
                @media screen and (max-width:991px){
                    flex-direction: column;
                    gap: 20px;
                }

                .main-blog-card{
                    @media screen and (min-width:991px) {
                        width: 60%;
                        padding: 50px 0px;
                    }
                    @media screen and (max-width:768px){
                        margin-top: -50px;
                       
                    }
                    display: flex;
                    flex-direction: column;
                    gap:20px;
                    //align-items: center;
                    //box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; 
                    h1{
                        width: 100%;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 36px;
                        color: var(--black-100, #1e1e26);
                        font-family: "Ubuntu", sans-serif;
                        font-style: normal;
                        margin-bottom: 0;
                        @media screen and (max-width:768px) {
                            padding-top: 50px;
                            width: 100%;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 28px;
                        }
                      } 
                      img{
                        align-items: center;
                        width:100%;
                        height: 450px;
                        border-radius: 3%;
                        object-fit: cover;
                        aspect-ratio : 2 / 1; 
                        width: 100%;        
                        height: auto; 
                        @media screen and (max-width: 991px) {
                        aspect-ratio : 2 / 1; 
                        width: 100%;        
                        height: auto; 
                        }
                    }            
                    .blog-content{
                          h1{
                             width: 80%;
                             font-size: 28px;
                             font-weight: 700;
                             line-height: 36px;
                             color: var(--black-100, #1e1e26);
                             font-family: "Ubuntu", sans-serif;
                             font-style: normal;
                             margin-bottom: 0;
                             @media screen and (max-width:768px) {
                                 padding-top: 50px;
                             }
                           }
                           h2{
                            width: 90%;
                            font-size: 24px;
                            font-weight: 700;
                            line-height: 36px;
                            color: var(--black-100, #1e1e26);
                            font-family: "Ubuntu", sans-serif;
                            font-style: normal;
                            margin-bottom: 0;
                            @media screen and (max-width: 991px) {
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 30px;
                            }
                            
                          }
                           h3{
                             font-family: "Open Sans", sans-serif;
                             font-style: normal;
                             font-size: 20px;
                             font-weight: 700;
                             line-height: 32px;
                             color: var(--black-100, #1e1e26);
                             margin-bottom: 0;
                           }
                           h4{
                             font-family: "Open Sans", sans-serif;
                             font-style: normal;
                             font-size: 18px;
                             font-weight: 600;
                             line-height: 32px;
                             color: var(--black-100, #1e1e26);
                             margin-bottom: 0;
                           }
                         img{
                             align-items: center;
                             width:100%;
                             height: 450px;
                             border-radius: 3%;
                             object-fit: cover;
                             @media screen and (max-width: 991px) {
                                aspect-ratio : 2 / 1; 
                                width: 100%;        
                                height: auto; 
                            }
                         }
                           p,ul>li{
                             color: var(--black-100, #1e1e26);
                             font-family: 'Open Sans', sans-serif;
                             font-size: 16px;
                             font-style: normal;
                             font-weight: 400;
                             line-height: 24px;
                             text-align: justify;
                             @media screen and (max-width: 991px) {
                                font-size: 14px;
                           }
                        }
         
                         strong{
                             font-family: "Open Sans", sans-serif;
                             font-style: normal;
                             font-size: 16px;
                             font-weight: 700;
                             line-height: 26px;
                             color: var(--black-100, #1e1e26);
                             @media screen and (max-width: 991px) {
                                font-weight: 600;
                           }
                             
                         }

                         table, th, td ,tr{
                            border: 1px solid #1E1E26;
                            text-align: center !important;
                          }
                      
                          table {
                            margin-top: 10px;
                          }
                      
                          th {
                            font-family: 'Open Sans', sans-serif;
                            padding: 0 15px;
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 24px;
                            color: #1E1E26;
                            margin-bottom: 8px;
                            @media screen and (max-width: 991px) {
                                font-size: 15px;
                                font-weight: 600;
                                line-height: 20px;
                                padding: 0 0;
                            }
                          }
                          td {
                            font-family: 'Open Sans', sans-serif;
                            padding: 0 10px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20px;
                            color: #1E1E26;
                            margin-bottom: 8px;
                            @media screen and (max-width: 991px) {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                padding: 5px;
                            }
                          }
                    
                    }
    
                }
                .extra-blog{
                    @media screen and (min-width:991px) {
                        width: 30%;
                        padding: 50px 0px;
                        display: flex;
                        flex-direction: column;
                        gap: 48px;

                    }
                    @media screen and (max-width:768px){
                        //margin-top: 50px;
                        display: flex;
                        flex-direction: column;
                        gap: 32px;
                       
                    }
                    .extra-title{
                        font-family: 'Ubuntu',sans-serif;
                        font-size: 24px;
                        font-weight: 700;
                        line-height: 48px;
                        text-align: left;

                    }
                    .extra-blog-outer{
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        .extra-blog-div {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            gap:16px;
                            img {
                              border-radius: 16px;
                              aspect-ratio : 8/7; 
                              width: 45%;
                              height: 100%;
                              object-fit:cover;
                              
                            }
                            .mweb {
                                @media screen and (max-width: 1024px) {
                                  text-align: left;
                                  display: flex;
                                  flex-direction: column;
                                }  
                                h3 {
                                  font-family: 'Open Sans', sans-serif;
                                  font-size: 17px;
                                  font-weight: 600;
                                  line-height: 26px;
                                  text-align: left;
                                  color:  #4B4B51;
    
                              
                                  @media screen and (max-width: 1024px) {
                                    margin: 0;
                                    font-family: 'Open Sans', sans-serif;
                                    font-size: 16px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    text-align: left;
                                  }
                                }
                                a {
                                  display: inline-block;
                                  margin-top: 10px;
                                  color: #22BBB0;
                                  text-decoration: none;
                                  font-family: 'Open Sans',sans-serif;
                                  margin: 0;
                                  font-size: 16px;
                                  font-weight: 500;
                                  line-height: 22px;
                                  @media screen and (max-width: 1024px) {       
                                     font-size: 16px;
                                     font-weight: 500;
                                     line-height: 24px;
                                     text-align: left;
                               
                                   }
                                }
                              }
                          }
                    }
                    
                    }
                  
                   

                }
    
            }
           
        }
    }
