@import "./src/assets/styles/font_color_mixins.scss";

.co-passenger-detail-form-component{



  @media screen and (max-width: 1024px) {
    .submit-button {
      padding: 16px;
      gap: 10px;
      width: 343px;
      height: 56px;
      background: #22BBB0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 40px;
      color: white;
    }

    .gender-box-right,
    .gender-box-left {
      padding: 16px 0;
      width: 100%;
      // border: 1px solid #D2D2D4;
      border: 1px solid #1010da;
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }

    .gender-box-right {
      border-radius: 0px 12px 12px 0px;
    }

    .gender-box-left {
      border-radius: 12px 0px 0px 12px;
    }

    .active-tab {
      background: #D3F1EF;
      /* Teal / 100 */

      border: 1px solid #22BBB0;
    }
  }
  @media screen and (min-width: 1024px) {
    .half-wd-desktop {
      width: 50vw;
    }
    .gender-box-right,
    .gender-box-left {
      padding: 16px 0;
      width: 100%;
      border: 1px solid #D2D2D4;
      text-align: center;

      p {
        margin-bottom: 0;
      }
    }

    .gender-box-right {
      border-radius: 0px 12px 12px 0px;
    }

    .gender-box-left {
      border-radius: 12px 0px 0px 12px;
    }

    .active-tab {
      background: #D3F1EF;
      /* Teal / 100 */
      padding: 16px 0 !important;
      border: 1px solid #22BBB0  !important;
    }

    .submit-button {
      padding: 16px;
      gap: 10px;
      width: 302px;
      height: 60px;
      background: #22BBB0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 40px;
      color: white;
    }
  }

}
