@import "../../assets/styles/font_color_mixins";
@import "_variable.scss";


$color_1: #A5A5A8;
$color_2: #00A095;
//$black-1E1E26: #1E1E26;
//$gray-78787d: #78787D;
$color_5: #4B4B51;
$color_6: #FFFFFF;
$color_7: rgba(255, 255, 255, 0.8);
$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;
$background-color_1: #FFFFFF;
$background-color_2: #F4FCFB;
$background-color_3: rgba(0, 0, 0, 0.5);

.lounge-page {
 //background-color: #1E1E26;
   margin-top: 0px;


  .content {
    max-width: 1000px;
    margin: auto;
  }

  .lounge-section {
    padding: 0 24px 14px;
    margin-top: auto;

    .brand-section {
      display: flex;

      .p1 {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $color_1;
        margin: 0;
      }

      .p2{
        height: 22px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $color_2;
        margin: 0;
      }
    }
  }

  .search-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-top: 26px;

    .text-section {
      h1 {
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: $black-1E1E26;
        margin: 0;
      }
    }
  }

  .search-bar-section {
    background: #FFFFFF;
    border: 1px solid #D2D2D4;
    border-radius: 12px;
    padding: 16px 0px;

    .form {
      position: relative;

      .search-area {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        width: 100%;
        border: none;
        padding-left: 47.5px;

        &:focus {
          outline: none;
        }
      }

      button {
        position: absolute;
        top: -3px;
        left: 15px;
        border: none;
        background-color: $background-color_1;
      }
    }
  }

  .image-section {
    padding: 0px 24px;
    margin-bottom: 30px;

    .para-section {
      padding: 40px 0px;
      width: 100%;
      margin: auto;

      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $black-1E1E26;
        margin: 0;
        text-align: justify;
      }
    }

    .service-image-section {
      display: grid;
      grid-template-columns: 50% 50%;
      text-align: center;
      gap: 20px 0px;

      .image1 {
        padding: 0px 17px;

        img {
          margin-bottom: 22px;
          width:64px;
          height:64px
        }

        p {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: $black-1E1E26;
        }
      }
    }

    .service-section h3 {

      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: $black-1E1E26;
      margin: 0;

    }
  }

  .slider-section {
    padding: 0px 24px;

    .text-area {
      margin-bottom: 16px;

      h2 {
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: $black-1E1E26;
      }

      p {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $gray-78787d;
        margin-right: 94px;
      }
    }

    .slider-image {
      width: 100%;
      border-radius: 10px;
    }

    .slider-content {
      h3{
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $black-1E1E26;
      }

      p,a {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: $color_5;
      }

      .icon {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: $color_5;
      }

      .box-1 {
        background-color: $background-color_2;
        border: 1px solid #E8E8E9;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        height: auto;
        width: 313px;
      }
    }
    

    .control-icon {
      display: flex;
      position: absolute;
      right: 2px;
      top: -90px;

      .carousel-control-prev-sec {
        padding: 10px 16px;
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        border: none;
      }
     

    }
    .carousel-inner{
      margin: 0px 4% 0 4%;
      height: auto;
      padding: 56px 0 100px;
      width:85%;

    }
   .swiper-button-prev,
     .swiper-button-next {
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.09);
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    margin-top: -24px;

}
     .swiper-button-prev:after,
  .swiper-button-next:after {
    color: #000;
    font-size: 20px;
  }
  .swiper {
    position: relative;
    height: auto;
    padding: 0 60px 40px 10px;
}

 .swiper .swiper-button-prev {
    left: -18px;
}

  .swiper .swiper-button-next {
    right: -18px;
}

    // .swiper{


    //   .swiper-slide{
    //    // max-width: 278px !important;
    //   }
    // }
  }

  .location-section {
    position: relative;
    padding: 24px 24px;
    margin-bottom: 10px;

    img {
      position: absolute;
      top: 0px;
      left: 150px;
    }

    .bg-image {
      position: absolute;
      top: 0px;
      left: 80px;
    }

    .location-section-list {
      padding: 32px 48px;
      background: #22BBB0;
      border-radius: 24px;

      .text-area {
        h2 {
          font-family: $font-family_2;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: $color_6;
          text-align: center;
        }
      }

      .list-i-2 {
        .list1 {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-image: url($background-cdn + 'assets/images/static-images/location1.jpg');
          background-color: $background-color_3;
          background-blend-mode: overlay;
          background-position: center;
          background-size: cover;
          border-radius: 12px;
          height: 80px;
          padding: 26px;
        }
      }

      .list-i-3 {
        .list1 {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-image: url($background-cdn + 'assets/images/static-images/location2.jpg');
          background-color: $background-color_3;
          background-blend-mode: overlay;
          background-position: center;
          background-size: cover;
          border-radius: 12px;
          height: 80px;
        }
      }

      .list-i-4 {
        .list1 {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-image: url($background-cdn + 'assets/images/static-images/location3.jpg');
          background-color: $background-color_3;
          background-blend-mode: overlay;
          background-position: center;
          background-size: cover;
          border-radius: 12px;
          height: 80px;
        }
      }

      .list1 {
        h2 {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          color: $color_6;
          text-align: center;
        }

        p {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          color: $color_7;
          margin-bottom: 0px;
        }
      }
    }
  }
  .cominglist-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .cominglist { 
    width: 80%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
    display: flex;
    align-items: center;
   
    background-image: url($background-cdn + 'assets/images/static-images/location1.jpg');
    background-color: $background-color_3;
    background-blend-mode: overlay;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    height: 80px;
    padding: 26px;
    margin-top: 10px;
    margin: 20px;
    
  }


  .cominglist img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    height: 100%;
    opacity: .7;    
  }


  .cominglist h2 {
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    margin: 0;
    font-family: $font-family_1;
  }

  .cominglist p {

    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 18px;

  }

  .lounge-section-2 {
    padding: 32px 24px;

    .brand-section {
      display: flex;

      h5 {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: $color_1;
        margin: 0;
      }

      h4 {
        height: 22px;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $color_2;
        margin: 0px 0px;
      }
    }

    .text-section {
      h2 {
        font-family: $font-family_2;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: $black-1E1E26;
        margin: 30px 0px;
      }
    }

    .image-section2 {
      img {
        width: 100%;
      }
    }
  }

  .winner-section {
    padding: 30px 24px;
    margin-bottom: 300px;

    h2 {
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: $black-1E1E26;
    }

    p {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: $gray-78787d;
      margin-bottom: 0px;
    }

    .winner-list-items {
      row-gap: 33px;

      .item-1 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px 30px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
        border-radius: 12px;

        img {
          border-radius: 12px;
        }

        h2 {
          font-family: $font-family_1;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: $black-1E1E26;
        }
      }
    }
  }

  .terms-condition {
    background: #F0FAF9;
    padding: 40px 24px;

    h2 {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $black-1E1E26;
    }

    p {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black-1E1E26;
    }

    .terms-text {
      max-width: 1000px;
      margin: auto;
    }
  }

  .winner-list {
    margin-top: 20px;
    margin-top: 33px;
  }

  @media (min-width: 767px) {
    
    .image-section {
      .service-image-section {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        text-align: center;
        gap: 20px 0px;
      }
    }
    
  }
  @media (min-width: 1024px) {
    .image-section {
      .para-section {
        padding: 40px 0px;
        width: 100%;
        margin: auto;
      }

      .service-image-section {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        //grid-template-columns: 20% 20% 20% 20% 20%;
        text-align: center;
        gap: 20px 0px;
        width: 60%;
        margin: auto;

        .image1 {
          padding: 0px 5px;
        }
      }
    }
   .swiper {
      padding: 0 20px 40px;
  }

   .swiper .swiper-button-next {
      right: 10px;
  }

    .location-section {
      margin-bottom: 0px;
      .location-section-list {
        .text-area {
          h2 {
            text-align: left;
          }
        }
      }
    }
  }
 
  @media (min-width: 1280px) {
    .lounge-section {
      padding: 32px 0px;
    }
    .image-section {
      padding: 0;
      margin-bottom: 30px;
    }
    .slider-section {
      padding: 0px;
    }
     .carousel-inner {
      margin: 0px 0 0 0;
  }
    .location-section {
      position: relative;
      padding: 24px 0px;
    }
    .lounge-section-2 {
      padding: 32px 0px;
    }
    .winner-section {
      .winner-list-items {
        row-gap: 33px;
        column-gap: 81px;
        max-width: 1000px;
        margin: auto;

        .box-1 {
          padding: 0px;
          max-width: 278px;
        }
      }

      padding: 30px 0px;
      margin-bottom: 300px;
    }
  }
}
@media only screen and (max-width:767px){
.lounge-page{
  margin-top: 24px;

  .image-section {
    .service-image-section {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      text-align: center;
      gap: 20px 0px;
    }
  }

}

.control-icon{
  display: block;
}
.carousel-inner {
  padding: 35px 0 70px;
}
  .swiper{
  padding: 0 30px 15px;
}



}


