
.main-privacy-policy {
  .title {
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    color: #1E1E26;
    padding-top: 40px;
    @media screen and (max-width: 1023px) {
      padding-top: 20px;
      font-size: 26px;
    }
  }

  .terms-condition-modal{

    @media screen and (max-width: 1024px) {
      .modal {
        left: 0;
        width: 100%;
      }
      .modal-dialog {
        margin: 0;
        height: 100%;
      }

      .modal-content {
        border-radius: 15px;
        overflow: hidden;
        height: 100%;
      }
    }
    @media screen and (min-width: 1024px) {

      .modal {
        left: 50%;
        //transform: translateX(-50%);
        transform: translate(-50%, -50%);
        width: 75vw;
        //background: white;
        height: fit-content;

        top: 50%;
        z-index: 100000;
      }
      .modal-dialog {
        margin: 0;
      }

      .modal-content {
        border-radius: 15px !important;
        overflow: hidden;
      }
    }

  }

  .privacy-policy {
    padding: 20px 80px;
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1E1E26;

    @media screen and (max-width: 1023px) {
      padding: 16px;

    }
  }


}
