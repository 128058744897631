@import '../../assets/styles/font_color_mixins';

.page-not-found {

  .page-not-found-illustration {
    width: 284px;
    height: 286px;
  }

  .ticket-no {
    padding-top: 16px;
    max-width: 380px;
    width: 100%;
    margin: 0 auto;
  }

  .booking-confirmation {
    margin-left: 0;
  }


  .ticket-issue {
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 28px;
    color: #4B4B51;
  }

  .submit-button {
    background-color: $teal-22BBB0;
    color: white;
    padding: 16px;
    width: 300px !important;
    border-radius: 40px;
  }

  .combined-shape-image {
    width: 20px;
    height: 20px;
  }

  .gap-8 {
    gap: 8px;
  }

  .about-refund {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    max-width: 355px;
    width: 100%;
    text-align: left;
    margin: 0 0 54px 0;
    color: #78787D;

    span {
      font-weight: 700;
    }
  }

  .refund-status {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid var(--black-20, #D2D2D4);
    background: #FFF;
  }

  .gap-37 {
    gap: 37px;
  }

  .refund-status-title {
    color: var(--black-100, #1E1E26);
    font-family: Ubuntu, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    padding: 0 0 24px 0;
    text-align: center;
    font-size: 24px;
    margin: 0;
  }

  .refund-status-process {
    border-radius: 12px;
    background: var(--black-03, #F8F8F9);
    padding: 13px 46px 13px 13px;
    gap: 16px;

    p {
      margin: 0;
    }

    .refund-status-process-title {
      color: var(--black-100, #1E1E26);
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .refund-status-process-description {
      color: var(--black-60, #78787D);
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      span {
        font-weight: 700;
      }
    }

  }

  .payment-description {
    color: var(--black-60, #78787D);
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
  }

  .payment-data {
    color: var(--black-100, #1E1E26);
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
  }

  .payment-details {
    padding-top: 20px;
  }

  .submit-btn-section {
    padding-top: 70px;
  }

  @media screen and (max-width: 1023px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;
    }
    .white-fold-padding {
      padding: 32px 0 0
    }
    .button-border {
      border: 1px solid #1E1E26;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }
    .page-not-found-illustration {
      width: 142px;
      height: 143px;
    }
    .ticket-issue {
      color: #78787D;
      font-size: 14px;
      margin: 0;
      line-height: 22px;
      padding: 8px 0 20px 0;
    }
    .ticket-no {
      font-size: 20px;
      margin: 0;
      padding: 22px 0 0 0;
    }
    .submit-button {
      width: 343px !important;
      margin: 19px auto 0 auto;
    }
    .refund-status-image-part {
      padding: 0 16px 0 16px;
    }
    .refund-status {
      border-radius: 20px 20px 0 0;
      background: #FFF;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.05);
      padding: 0 16px 30px 16px;
      border: none;

      p {
        margin: 0;
      }

      .refund-status-process {
        padding: 17px 15.5px 26px 18.5px;
        border-radius: 12px;
        background: var(--black-03, #F8F8F9);
        gap: 14px;
      }

      .refund-status-title {
        font-size: 18px;
        line-height: 28px;
        padding: 16px 0;
        text-align: start;
      }

      .refund-status-process-title {
        color: var(--black-100, #1E1E26);
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .refund-status-process-description {
        color: var(--black-60, #78787D);
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        span {
          font-weight: 700;
        }
      }
    }
    .payment-details {
      padding: 20px 0 0 0;
    }
    .payment-description {
      color: var(--black-60, #78787D);
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .payment-data {
      color: var(--black-100, #1E1E26);
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
    .submit-btn-section {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .navbar-wrapper {
      padding-bottom: 24px;
      background: #1E1E26;
    }

    .white-fold-padding {

      display: flex;
      justify-content: center;
      padding: 80px 80px 44px 80px;

      .inner-div {
        width: 100%;
        max-width: 1400px;
      }
    }


    .button-border {
      border: 1px solid #7AD6D0;
      border-radius: 100px;
      padding: 12px 24px;
      width: fit-content;

      p {
        white-space: nowrap;
      }
    }
    .refund-status {
      margin: 0;
    }

  }
}