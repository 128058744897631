@import "_variable.scss";

// join hands components styl
$color_1: #00a095;
$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Ubuntu", sans-serif;

.partner-with-us-page {
  background-color: #1E1E26;

  .join-hands-part {
    .join-hands-mobile-device {
      gap: 116px;
    }

    .join-hands-arrow {
      width: 16px;
      height: 14px;
    }

    .about-us {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #1e1e26;
      margin-top: 16px;
    }

    .home,
    .about {
      font-family: $font-family_1;
      font-weight: 600;
      font-size: 14px;
      color: #78787d;
    }

    .about {
      font-weight: 700;
      color: $color_1;
    }

    .join-hands-desktop-device {
      gap: 4px;
    }

    .join-hands-heading {
      font-family: $font-family_2;
      font-weight: 700;
      font-size: 20px;
      color: #1e1e26;

      .chage-text-color {
        color: $color_1;
      }
    }

    .green-hands-image {
      width: 233px;
      height: 205px;
    }

    .green-hands-data {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #1e1e26;
    }

    .join-hands-btn {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      padding: 8px 12px 8px 16px;
      width: 209px;
      background: #22bbb0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 40px;
    }
  }

  @media (min-width: 1024px) {
    .join-hands-part {
      //background: #ffffff;
      padding: 0 50px;

      .green-hands-data {
        font-size: 16px;
      }

      .join-hands-heading {
        font-size: 40px;
        line-height: 48px;
        width: 393px;
      }

      .green-hands-image {
        width: 415px;
        height: 365px;
      }

      .join-hands-btn {
        padding: 16px;
      }

      .green-hands-part {
        gap: 50px;
      }
    }
  }
  @media (min-width: 1280px) {
    .join-hands-part {
      padding: 0 80px;
      // max-width: 1350px;
      // width: 100%;
      // margin: auto;
      .green-hands-part {
        gap: 183px;
      }
    }
  }

  //advertise-with-us component
  .advertise-with-us {
    background: #f0faf9;
    padding-left: 16px;

    .advertise-with-us-heading {
      font-family: $font-family_2;
      font-weight: 700;
      font-size: 20px;
      color: #1e1e26;
    }

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      width: 64px;
      height: 64px;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      display: none;
    }

    .advertisement-box {
      // height: fit-content;
      background: #ffffff;
      border: 1px solid #e8e8e9;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
    }

    .advertisement-text-box {
      // max-width: 600px;
      width: 100%;
      height: 275px;
    }

    .advertise-image {
      width: 108px;
      height: 108px;
      border-radius: 16px;
      margin: 14px 0px 14px 14px;
    }

    .advertise-name {
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #1e1e26;
    }

    .advertise-description {
      //font-family: $font-family_1;
      //font-style: normal;
      //font-weight: 600;
      //font-size: 14px;
      //line-height: 22px;
      color: #78787d;
      height: 60px;
    }

    .advertise-comment {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }

    .advertise-comment-change-text {
      color: $color_1;
      font-weight: 700;
    }
  }

  @media (min-width: 1024px) {
    .advertise-with-us {
      padding: 0 0 0 50px;

      .advertise-with-us-heading {
        font-size: 32px;
      }

      .advertisement-text-box {
        height: 230px;
      }

      .advertise-image {
        padding: 20px 16px 24px 24px;
        margin: 0;
        width: 222px;
        height: 232px;
      }

      .control-icon {
        display: flex;
        position: absolute;
        right: 5.7%;
        top: -60px;
      }

      .carousel-control-prev-sec {
        padding: 10px 16px;
        background: #ffffff;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        border: none;
      }

      .swiper-button-next {
        background-image: none !important;
      }

      .swiper-button-prev {
        background-image: none !important;
      }

      .advertise-name {
        font-size: 20px;
      }

      .advertise-description {
        font-weight: 400;
        font-size: 16px;
        // line-height: 24px;
        color: #1e1e26;
        height: 72px;
      }
    }
  }

  @media (min-width: 1280px) {
    .advertise-with-us {
      padding: 0 0 0 80px;

      .advertisement-text-box {
        height: 230px;
      }

      .advertise-description {
        height: 85px;
      }
    }
  }

  // getInTouch component
  .get-in-touch {
    .get-in-touch-image {
      width: 74px;
      height: 65px;
    }

    .get-in-touch-title {
      font-family: $font-family_2;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #1e1e26;
    }

    .get-in-touch-description {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #78787d;
    }

    .submit-button {
      padding: 8px 16px;
      width: 250px;
      background: #22bbb0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
      border-radius: 40px;
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
    }

    input[type="text"],
    input[type="email"] {
      //font-family: $font-family_1;
      //font-style: normal;
      //font-weight: 400;
      //font-size: 18px;
      color: #78787d;

    }

    input[type="file"] {
      //font-family: $font-family_1;
      //font-style: normal;
      //font-weight: 400;
      //font-size: 18px;
      color: #78787d;

    }

    //input {
    //  background: #ffffff;
    //  border: 1px solid #d2d2d4 !important;
    //  border-radius: 8px;
    //  max-width: 1000px;
    //  width: 100%;
    //}

    .text-label {
      font-family: $font-family_1;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #78787d;
    }

    select {
      //padding: 12px 16px;
      //max-width: 1000px;
      //width: 100%;
      //// height: 48px;
      //background: #ffffff;
      //border: 1px solid #d2d2d4 !important;
      //border-radius: 8px;
      //font-family: $font-family_1;
      //font-style: normal;
      //font-weight: 400;
      //font-size: 18px;
      color: #78787d;
      -webkit-appearance: none;
      appearance: none;

    }

    .nationality-suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 16px 0;
      max-height: 200px;
      overflow-y: auto;

      p {
        padding: 8px !important;
        cursor: pointer;
        margin-bottom: 0;
        border-bottom: none !important;
      }
    }

    & ::-webkit-scrollbar {
      width: 6px;
      height: auto;
      padding-left: 16px;
    }

    /* Track */
    & ::-webkit-scrollbar-track {
      padding-left: 16px;
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }

    /* Handle */
    & ::-webkit-scrollbar-thumb {
      background: #D2D2D450;
      border-radius: 14px;

    }

    /* Handle on hover */
    & ::-webkit-scrollbar-thumb:hover,
    & ::-webkit-scrollbar-thumb:active {
      background: #D2D2D4;
    }

    .select-wrapper,
    .select-wrapper-two, .label {
      position: relative;
    }

    .select-wrapper-two::after {
      content: "";
      background-image: url($background-cdn + "assets/images/selectArrow.png");
      background-repeat: no-repeat;
      width: 20px;
      height: 11px;
      position: absolute;
      top: 45%;
      right: 10%;
    }

    .select-wrapper-two {
      select {
        padding: 10px 16px;
        min-width: 90px;
        width: 100%;
      }
    }

    .select-wrapper::after, .label::after {
      content: "";
      background-image: url($background-cdn + "assets/images/selectArrow.png");
      background-repeat: no-repeat;
      width: 20px;
      height: 11px;
      top: 40%;
      right: 2%;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
    }

    select:focus-visible {
      outline: none;
    }

    .input-boxes input {
      //background: #ffffff;
      //border: 1px solid #d2d2d4 !important;
      //border-radius: 8px;
      //max-width: 750px;
      width: 100%;
    }

    input[type="number" i] {
      padding: 12px 160px 12px 16px;
      max-width: 750px;
      width: 100%;
    }

    .input-boxes {
      gap: 16px;
    }

    .label::after {
      background-image: url($background-cdn + "assets/images/selectFile.png");
      width: 20px;
      height: 17px;
    }

    label.label input[type="file"] {
      display: none;
    }

    .label {
      cursor: pointer;
      font-family: "Open Sans", "sans-serif";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      padding: 12px 16px;
      color: #1e1e26;
      background: #ffffff;
      border: 1px solid #d2d2d4;
      border-radius: 8px;
      display: inline-block;
      max-width: 1000px;
      width: 100%;
    }

    .label:invalid + span {
      color: #000000;
    }

    .label:valid + span {
      color: #ffffff;
    }
  }

  @media (min-width: 1024px) {
    .get-in-touch {
      padding: 40px 50px;
      border: 1px solid #d2d2d4;
      border-radius: 16px;

      .label {
        padding: 16px 20px;
        width: 381px;
      }

      .get-in-touch-image {
        width: 97px;
        height: 85px;
      }

      .get-in-touch-arrangement {
        max-width: 778px;
        width: 100%;
        margin: auto;
      }

      .get-in-touch-title {
        font-weight: 700;
        font-size: 32px;
      }

      .get-in-touch-description {
        font-size: 16px;
        color: #1e1e26;
      }

      .submit-button {
        padding: 16px;
        width: 277px;
      }

      .select-wrapper-two {

      }
    }
  }
  @media (min-width: 1280px) {
    .get-in-touch {
      padding: 58px 80px;
    }
  }

  .partner-application-form {
    max-width: 794px;
    padding: 24px;
    width: 100%;

    .upload-button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }

  }

}
