.blog-landing-page{

    .blog-main-content{
        display: flex;
        flex-direction: column;
        gap: 60px;
        width: 89%;
        margin: auto;
        @media screen and (min-width:1024px) {
            margin-top: 120px !important;
            gap: 60px;
        }
        @media screen and (max-width: 768px) {
          // margin-top: 120px !important;
          gap: 20px;
      }

        .searchbar-div{
            position: relative;
            width: 100%;
            box-shadow: 0px 4px 16px 0px #00000033;

            padding: 30px 80px 30px 80px;
            border-radius: 12px;
            background: #ffffff;
            border: 1px solid #FFFFFF80;
            margin-bottom: 58px;

            .semicircle {
                position: absolute;
                top: -52px; /* Adjust this value to move the semicircle more into or out of view */
                right: -58px; /* Adjust this value similarly */
                width: 100px; /* Diameter of the semicircle */
                height: 100px; /* Diameter of the semicircle */
                background-color: yellow;
                border-radius: 50%; /* Makes the shape a circle */
                clip-path: inset(51% 56% 0% 0%);; /* Clips the bottom half, creating a semicircle */
              }


            .innerSubdiv{
                width:100%;
                height:80%;
                margin:auto;
                display: flex;
                flex-direction: column;
                gap: 5%;
                border-radius:12px;
                border:1px;
                padding: 20px 18px;
    
    
                h1{
                font-family: "Ubuntu";
                font-size: 24px;
                font-weight: 700;
                color: #1E1E26;
                span{
                color: #22BBB0;
                }
                }
    
                .search-inputs-container{
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    gap: 15px;
                    .search-inputs-container-sub-div{
                        display: flex;
                        width:40%;
                        
                        
                        .flip {
                            position: relative;
                            right: 0;
                            width: 56px;
                            cursor: pointer;
                            z-index: 10;
                            margin: 0px -14px;
                        }
                    
                        //   .map_pin {
                        //     position: relative;
                        //     width: 24px;
                        //     left: calc(50% + 48px);
                        //     z-index:10;
                        //   }
                    
                        //   .circle {
                        //     position: absolute;
                        //     width: 24px;
                        //     left: 24px;
                        //     height: 30px;
                        //     top: 15px;
                        //     z-index:10;
                        //   }
                        .source-input-bar{
                            width: 46%;
                            height: 56px;
                            border-radius: 12px;
                            position: relative;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            .location {
                            position: absolute;
                            //left: 15px;
                            width: 25px;
                            font-family: 'Open Sans', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #1E1E26;
                            left:15px;
                            top:15px;
                            }
                            
                            input{
                                width: 100%;
                                height: 100%;
                                border-radius: 16px;
                                padding-left: 25%;
                            }
                            .search-suggestion-wrapper {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                border-radius: 12px;
                                background-color: white;
                                padding: 24px 24px 0;
                                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                                width: 100%;
                                max-height: 291px;
                                height: fit-content;
                                overflow-y: auto;
                                z-index: 100;
                            }
    
                        }
                        .destination-input-bar{
                            position: relative;
                            width: 46%;
                            height: 56px;
                            border-radius: 12px;
                            border: 1px solid rgba(0, 0, 0, 0.1);

                            .location {
                            position: absolute;
                            //left: 15px;
                            width: 25px;
                            font-family: 'Open Sans', sans-serif;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: #1E1E26;
                            left:15px;
                            top:15px;
                            }
                            input{
                                width: 100%;
                                height: 100%;
                                border-radius: 16px;
                                padding-left: 25%;
                            }
                            .search-suggestion-wrapper {
                                position: absolute;
                                top: 100%;
                                left: 0;
                                border-radius: 12px;
                                background-color: white;
                                padding: 24px 24px 0;
                                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                                width: 100%;
                                max-height: 291px;
                                height: fit-content;
                                overflow-y: auto;
                                z-index: 100;
                            }
    
                        }
                        
                    }
                    .date {
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 12px;
                        display: flex;
                        position: relative;
                        height: 56px;
                        align-items: center;
                        padding: 0px 5px;
                        .custome-height {
                        display: flex;
                        height: 24px;
                        position: relative;
                        padding: 22px 16px 22px 16px; 
                        align-items: center;
                        }
                        .cal-date{
                        color: #FFFFFF;
                        background-color: #22BCB1;
                        height: 24px;
                        border-radius: 8px;
                        
                        }
                
                        .today, .Tomorrow {
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: rgba(30, 30, 38, 0.6);
                        cursor: pointer;
                        width: 48px;
                        height: 48px;
                        border-radius: 8px;
                        text-align: center;
    
                        .day{
                            width: auto;
                            margin: auto;
                            height: 18px;
                        } 
                        .dated{
                            width: auto;
                            margin: auto;
                            height: 24px;
                        }
                        }
                
                        .selected-date {
                        color: #FFFFFF;
                        background-color: #22BCB1;
                        }
                
                        .Calendar-icon {
                        position: absolute;
                        left: 15px;
                        width: 25px;
                        font-family: 'Open Sans', sans-serif;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #1E1E26;
                        }
                
                        .fast-date-select {
                        display: flex;
                        gap: 12px;
                        height: 100%;
                        padding: 3px 12px 3px 12px;
                        
                        }
                
                        .date-value {
                        margin-left: 30px;
                        }
                
                        .calender {
                        width: 100px;
                        margin-left: 26px;
                        border: none;
                        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
                        //opacity: 0.1;
                        height: 24px;
                        outline: none;
                        cursor: pointer;
                        }
                        .calender-selected{
                        width: 100px;
                        margin-left: 26px;
                        border: none;
                        border-right: 1.5px solid rgba(0, 0, 0, 0.1);
                        //opacity: 0.1;
                        height: 24px;
                        outline: none;
                        cursor: pointer;
                        color: #FFFFFF;
                        }
                    }
    
                    .homepage-d-search-button{
                        width:20%;
                        height:56px;
                        padding:16px;
                        border-radius:100px;
                        background: #22BBB0;
                        color:white;
                        //styleName: Body 1/App/16/Semibold;
                        font-family: 'Open Sans';
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: center;
    
                        .Search {
                        position: relative;
                        left: -5px;
                        width: 20px;
                        }
                    }
    
                }
            
    
            }

        }
        .mweb404search{
            background-color:  #FFFFFF;
            position: relative;
            box-shadow: 0px 4px 16px 0px #00000033;
            border-radius: 16px;
            .semicircle {
                position: absolute;
                top: -52px; /* Adjust this value to move the semicircle more into or out of view */
                right: -58px; /* Adjust this value similarly */
                width: 100px; /* Diameter of the semicircle */
                height: 100px; /* Diameter of the semicircle */
                background-color: yellow;
                border-radius: 50%; /* Makes the shape a circle */
                clip-path: inset(51% 56% 0% 0%);; /* Clips the bottom half, creating a semicircle */
              }
            .mweb-head{
                padding: 10px 16px ;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                text-align: left;
                color: #FFFFFF;

            }
            .mweb-desc{
            visibility: hidden;
            margin: 0px 16px  10px 16px ;
            font-family: 'Open Sans', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            }
        .MobileTopBus {
            background-color:  #FFFFFF;
            padding: 20px 0px 20px;
            // background-image: url("../../assets/revamp/mwebbgImage.png");
            background-size: cover;
            //position: sticky;
            //top: 78px;
            //overflow: hidden;
            .w-48px {
              width: 48px;
            }
        
            .Rectangle {
              width: 56px;
              position: absolute;
              left: 50%;
              bottom: -48px;
              transform: translate(-50%, 0);
            }
        
            .Bus_illustration {
              width: 132px;
              height: 86px;
              position: absolute;
              right: 0;
            }
        
            .title {
              width: 219px;
              padding: 16px 16px 58px 16px;
              font-family: 'Ubuntu', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 32px;
              color: #1E1E26;
            }
        
            .search {
              .enter-place {
                background: #FFFFFF;
                // border: 1px solid rgba(0, 0, 0, 0.1);
                border: 1px solid #E8E8E9;
                border-radius: 16px;
                margin: 0 16px !important;
                padding: 20px;
        
                .start_location {
                  padding-bottom: 16px;
                  border: none;
                  border-bottom: 1px solid rgba(30, 30, 38, 0.12);
        
                  &:focus-visible {
                    border: none;
                    border-bottom: 1px solid rgba(30, 30, 38, 0.12);
                    outline: none;
                  }
                }
        
                .end_location {
                  padding-top: 16px;
                  border: none;
                  border: 1px solid #E8E8E9;
                  outline: none;
        
                  &:focus-visible {
                    border: none;
                    outline: none;
                  }
                }
        
                .pace_left_icon {
                  display: flex;
                  align-items: center;
                }
        
                .mb_input_left_icon {
                  width: 16px;
                  height: 72px;
                }
        
                .flip {
                  width: 56px;
                  height: 56px;
                }
              }
            }
        
            .date {
              background: #FFFFFF;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 12px;
              display: flex;
              padding: 10px 0 10px 0;
              position: relative;
              margin: 16px;
        
              .today, .Tomorrow {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: rgba(30, 30, 38, 0.6);
                width: 48px;
                height: 48px;
                border-radius: 8px;
                text-align: center;
        
              }
        
              .selected-date {
                color: #FFFFFF;
                background-color: #22BCB1;
              }
        
              .Calendar-icon {
                position: relative;
                left: 15px;
                width: 32px;
                height: 32px;
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #1E1E26;
                transform: translateX(-50%);
              }
              
        
              .fast-date-select {
                display: flex;
                //margin-left: 16px;
                gap: 4px;
                //height: 24px;
                height: 100%;
                padding: 2px 0 2px 12px;
              }
        
              .date-value {
                margin-left: 30px;
              }
        
              .calender-parent-div{
                color: #FFFFFF;
                background-color: #22BCB1;
                border-radius: 8px;
                padding: 0px 5px;
              }
        
              .calender {
                width: 100%;
                padding-right: 0px;
                margin-left: 6px;
                border: none;
                border-right: 1.5px solid #1E1E26;
                outline: none;
              }
              .calender-active {
                width: 100%;
                padding-right: 0px;
                margin-left: 6px;
                border: none;
                border-right: 1.5px solid #1E1E26;
                outline: none;
                color: #ffffff;
              }
            }
        
            .seat-count {
              position: relative;
              display: flex;
              background: #FFFFFF;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 12px;
              padding: 20px 16px;
              margin: 16px;
              color: #1E1E2699;
        
              .current-count {
                font-family: 'Open Sans', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #1E1E26;
              }
        
              .inner-count {
                display: flex;
                margin-left: 48px;
                gap: 12px;
                position: absolute;
                right: 26px;
              }
        
              .seat_count {
        
                width: 24px;
                margin-right: 12px;
              }
        
              .count-plus, .count-sub {
                img {
                  width: 24px;
        
                }
              }
        
            }
        
            .searct-btn {
              position: relative;
              background: #29BDB5;
              border-radius: 100px;
              padding: 16px 25px 0px 25px;
              height: 56px;
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #FFFFFF;
              // margin: 16px 16px 0px 16px;
              width: fit-content;
              margin: auto;
        
              .Search {
                margin-right: 12px;
                width: 20px;
              }
            }
        
            .btn-start-search {
              border: 1px solid rgba(30, 30, 38, 0.1);
              box-shadow: 0 4px 14px rgba(0, 0, 0, 0.04);
              border-radius: 12px;
              margin: 16px;
              padding: 20px 18px;
              background: white;
              position: absolute;
              width: calc(100% - 32px);
              bottom: -48px;
              font-family: 'Open Sans', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: rgba(30, 30, 38, 0.6);
        
              .search {
                margin-right: 14px;
                width: 20px;
                height: 20px;
              }
            }
        
            .search-input-wrapper {
              position: relative;
        
              .search-suggestion-wrapper {
                position: absolute;
                top: 100%;
                left: 0;
                border-radius: 12px;
                background-color: white;
                padding: 24px 24px 0;
                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
                width: fit-content;
                max-height: 291px;
                height: fit-content;
                overflow-y: auto;
                z-index: 100;
              }
            }
          }
        }



    }
}