.parent-div{
    width: 100%;
    .category-name{
      font-family: 'Open Sans',sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 48px;
      text-align: left;
      color: #1E1E26;
    }
    .card-main-div {
        display: flex;
        width: 100%;
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        @media screen and (max-width:1024px) {
          gap: 0px;
        }
      
        .card{
          flex: 1 1 calc(50% - 20px);
          box-sizing: border-box;
          gap: 20px;
          box-shadow: none;
          height: 464px;
          @media screen and (max-width:1028px) {
            flex: 1 1 100%;
            flex-direction: row;
            gap: 12px; 
            height: 200px;
            box-shadow: none;
        }

        .card-img-top {
            width: 100%;
            object-fit: cover;
            height: calc(100% / (587 / 286));
            border-radius: 16px;
            @media screen and (max-width:1028px) {
                width: 45%;
                height: 76%;
                object-fit: cover;
                border-radius: 16px;
                align-self: center;
                margin-left : 5px;
            }
          }

        .card-body{
          @media screen and (max-width:768px) {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 0;
        }
            .card-title {
                margin-top: 8px;
                margin-bottom: 0;
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                color: #4B4B51;

                @media screen and (max-width:768px) {
                    font-family: 'Open Sans', sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                }
            }
          
              .card-text {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 8px;
                margin-bottom: 0;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                @media screen and (max-width:768px) {
                    display: none;
                }
              }
          
              .blog-link {
                margin-top: 8px;
                margin-bottom: 0;
                display: inline-block;
                color: #00A095;
                @media screen and (max-width:768px) {
                  margin-bottom: 10px;
              }
              }
            }

        }
      
      }

      
      
      
}